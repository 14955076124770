export enum UnitTypeId {
  Linear = 'Linear',
}

export enum LinearUnit {
  Meter = 'm',
}

export interface UnitType {
  id: string;
  units: Unit[];
}

export interface Unit {
  name: string;
  symbol: string;
  aliases: string[];
  isNativeUnit: boolean;
  offsetToNative: number;
  factorToNative: number;
}

export interface ResolvedUnit {
  unit: Unit;
  unitType: UnitType;
  displayName: string;
}

export interface Scaler {
  unit: string;
  unitType: string;
  value?: number;
}

export interface RoundedScaler extends Scaler {
  roundedValue?: number;
  symbol: string;
}
