<!-- Header -->
<div class="flex flex-row">
  <div class="flex flex-col grow">
    <div class="font-semibold">Measurement Properties</div>
    <div class="text-sm text-secondary">
      {{ isNew ? 'New measurement' : measurement.name }}
    </div>
  </div>
  <div>
    <button modus-icon-button (click)="cancelChanges()" [disabled]="saving">
      <md-icon>close</md-icon>
    </button>
  </div>
</div>

<!-- Loader -->
@if (!measurement.projection) {
  <div class="flex flex-col grow items-center justify-center py-12">
    <nzc-busy-indicator
      [busy]="isBusy"
      [label]="measureState + '...'"
      class="text-sm break-anywhere"
    >
      <div class="text-white bg-red-light rounded-sm p-2 text-sm break-anywhere">
        <div class="font-bold">Error projecting to alignment!</div>
        <div class="mt-2">{{ projectionError }}</div>
      </div>
    </nzc-busy-indicator>
  </div>
} @else {
  <!-- Detail -->
  <div class="my-3 flex flex-col">
    <form [formGroup]="formGroup">
      <modus-form-field>
        <modus-label>Name</modus-label>
        <input modus-input [formControl]="formGroup.controls.name" />
      </modus-form-field>
    </form>

    <div class="prop">
      <md-icon modus-tooltip="Station">ruler</md-icon>
      <div>{{ measurement.projection.alignmentStation?.station | uom }}</div>
    </div>
    <div class="prop">
      <md-icon modus-tooltip="Offset">align_center_horiz</md-icon>
      <div>{{ measurement.projection.alignmentStation?.offset | uom: true }}</div>
    </div>
    <div class="prop">
      <md-icon modus-tooltip="Height">align_center_vert</md-icon>
      <div>{{ measurement.projection.alignmentStation?.height | uom: true }}</div>
    </div>
    <div class="prop">
      <md-icon modus-tooltip="Coordinates" tooltipClass="arrow-left">move</md-icon>
      <div>{{ measurement.projection.alignmentCoordinate?.x | uom }}</div>
    </div>
    <div class="prop">
      <md-icon></md-icon>
      <div>{{ measurement.projection.alignmentCoordinate?.y | uom }}</div>
    </div>
    <div class="prop">
      <md-icon></md-icon>
      <div>{{ measurement.projection.alignmentCoordinate?.z | uom }}</div>
    </div>
  </div>
  <div class="flex flex-row justify-end space-x-2">
    <button modus-stroked-button color="secondary" (click)="cancelChanges()" [disabled]="saving">
      Cancel
    </button>
    <button
      modus-flat-button
      color="primary"
      (click)="saveChanges()"
      [disabled]="formGroup.invalid || saving"
    >
      <nzc-busy-indicator [busy]="saving" label="Saving">Save</nzc-busy-indicator>
    </button>
  </div>
}
