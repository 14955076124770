import { ICompactPlotSet, INumericAxis, PointCoordinate } from '../../api';
import { Lookup } from '../../shared';

export interface DirectionVectors {
  forward: PointCoordinate;
  up: PointCoordinate;
  right: PointCoordinate;
}

export type StationPositionComponents =
  | 'X'
  | 'Y'
  | 'Z'
  | 'ForwardX'
  | 'ForwardY'
  | 'ForwardZ'
  | 'UpX'
  | 'UpY'
  | 'UpZ'
  | 'RightX'
  | 'RightY'
  | 'RightZ';

export interface StationPositions extends ICompactPlotSet<INumericAxis> {
  coordinates: Lookup<number, PointCoordinate>;
  directions: Lookup<number, DirectionVectors>;
}

export interface NavigationArgs {
  cameraMode: AlignmentCameraMode;
  cameraHeight: number;
  cameraDistance: number;
  cameraOffset: number;
  chainage?: number;
  reverse: boolean;
  lockLookAt: boolean;
  yawOffset?: number;
  pitchOffset?: number;
}

export const defaultNavigationArgs: Readonly<NavigationArgs> = {
  cameraMode: 'Trolley',
  cameraHeight: 0,
  cameraDistance: 25,
  cameraOffset: 0,
  chainage: undefined,
  reverse: false,
  lockLookAt: false,
};

export type AlignmentCameraMode = 'Trolley' | 'Attached' | 'Free';
