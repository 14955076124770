import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearAuth, SetConnectToken } from './auth.actions';

export interface AuthStateModel {
  connectToken?: string;
}

@State<AuthStateModel>({
  name: 'authState',
})
@Injectable()
export class AuthState {
  @Selector([AuthState]) static accessToken(state: AuthStateModel) {
    return state.connectToken;
  }

  @Action(SetConnectToken) setConnectToken(
    ctx: StateContext<AuthStateModel>,
    action: SetConnectToken,
  ) {
    ctx.patchState({ connectToken: action.connectToken });
  }

  @Action(ClearAuth) clearAuth(ctx: StateContext<AuthStateModel>) {
    ctx.setState({});
  }
}
