import { Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SimpleChangesTyped } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusMenuModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { EMPTY, finalize, map, switchMap } from 'rxjs';
import { Alignment, AlignmentMeasurementApiService } from '../../api';
import {
  BusyIndicatorComponent,
  DialogService,
  OverlayPosition,
  OverlayService,
  arrayRemove,
  arrayReplace,
  arraySort,
} from '../../shared';
import {
  AppState,
  MeasurementDisplayStatus,
  MeasurementViewModel,
  mapMeasurement,
} from '../../state';
import { MeasurementDetailComponent } from '../measurement-detail/measurement-detail.component';

@UntilDestroy()
@Component({
  selector: 'nzc-measurement-list',
  standalone: true,
  imports: [
    ModusButtonModule,
    ModusIconModule,
    ModusMenuModule,
    ModusTooltipModule,
    BusyIndicatorComponent,
  ],
  templateUrl: './measurement-list.component.html',
  styleUrls: ['./measurement-list.component.scss'],
})
export class MeasurementListComponent implements OnChanges, OnDestroy {
  @HostBinding('class') componentClasses = 'flex flex-col grow';

  @Input() public alignment?: Alignment;

  private readonly workspace = this.store.selectSnapshot(AppState.workspace);

  public readonly MeasurementDisplayStatus = MeasurementDisplayStatus;
  public loading = false;
  public measurements: MeasurementViewModel[] = [];

  constructor(
    private store: Store,
    private measurementService: AlignmentMeasurementApiService,
    private dialogService: DialogService,
    private overlayService: OverlayService,
  ) {}

  public ngOnChanges(changes: SimpleChangesTyped<MeasurementListComponent>): void {
    const alignmentChange = changes.alignment;
    if (alignmentChange) {
      this.loadMeasurements();
    }
  }

  public ngOnDestroy(): void {
    this.removeMeasurments();
  }

  private removeMeasurments(): void {
    this.measurements.forEach((m) => this.hideMeasurement(m));
  }

  private loadMeasurements(): void {
    this.removeMeasurments();
    this.measurements = [];

    // Ensure required values are set
    if (!this.alignment) {
      return;
    }

    this.loading = true;
    this.measurementService
      .getMeasurements(this.alignment?.id)
      .pipe(
        map((measurements) => measurements.map(mapMeasurement)),
        untilDestroyed(this),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (measurements) => {
          this.measurements = arraySort(measurements, (m) => m.name);
        },
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }

  public toggleMeasurement(model: MeasurementViewModel) {
    if (model.state === MeasurementDisplayStatus.Hidden) {
      this.showMeasurement(model);
    } else {
      this.hideMeasurement(model);
    }
  }

  private async showMeasurement(model: MeasurementViewModel): Promise<void> {
    model.state = MeasurementDisplayStatus.Visible;
    await this.workspace.createMeasurementMarkup(model);
  }

  private async hideMeasurement(model: MeasurementViewModel): Promise<void> {
    model.state = MeasurementDisplayStatus.Hidden;
    await this.workspace.removeMeasurementMarkup(model);
  }

  public deleteMeasurement(model: MeasurementViewModel): void {
    model.deleting = true;
    this.dialogService
      .showConfirmation(`Are you sure you want to delete measurement [${model.name}]?`)
      .pipe(
        untilDestroyed(this),
        switchMap((confirmed) =>
          confirmed
            ? this.measurementService.deleteMeasurement(model.alignmentId, model.id)
            : EMPTY,
        ),
        finalize(() => (model.deleting = false)),
      )
      .subscribe({
        next: () => {
          // On deletion, remove from measurements
          arrayRemove(this.measurements, model);
          this.hideMeasurement(model);
        },
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }

  public async measure() {
    // Ensure required values are set
    if (!this.alignment) {
      return;
    }

    const measurement: Partial<MeasurementViewModel> = {
      alignmentId: this.alignment.id,
    };

    this.overlayService
      .showOverlay(MeasurementDetailComponent, measurement, OverlayPosition.Bottom)
      .afterClosed<MeasurementViewModel>()
      .pipe(untilDestroyed(this))
      .subscribe((savedMeasurement) => {
        if (savedMeasurement) {
          this.measurements.push(savedMeasurement);
          this.measurements = arraySort(this.measurements, (m) => m.name);
          this.showMeasurement(savedMeasurement);
        }
      });
  }

  public editMeasurement(measurement: MeasurementViewModel) {
    this.overlayService
      .showOverlay(MeasurementDetailComponent, measurement, OverlayPosition.Bottom)
      .afterClosed<MeasurementViewModel>()
      .pipe(untilDestroyed(this))
      .subscribe((savedMeasurement) => {
        if (savedMeasurement) {
          arrayReplace(this.measurements, measurement, savedMeasurement);
          this.measurements = arraySort(this.measurements, (m) => m.name);

          // If edited, keep display state
          if (measurement.state === MeasurementDisplayStatus.Visible) {
            this.showMeasurement(savedMeasurement);
          }
        }
      });
  }
}
