import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of, switchMap } from 'rxjs';
import { AlignmentExportApiService, TrimBimExportArgs } from '../../api';
import { ClearNavigationState } from '../navigation';
import { CacheDisplaySetting } from './display-setting.actions';
import { DisplaySettingState } from './display-setting.state';

@Injectable({ providedIn: 'root' })
export class DisplaySettingService {
  constructor(
    private store: Store,
    private alignmentExportApiService: AlignmentExportApiService,
  ) {}

  public getDisplaySettings(alignmentId: string) {
    return this.store
      .selectOnce(DisplaySettingState.getSettings(alignmentId))
      .pipe(
        switchMap((settings) =>
          settings ? of(settings) : this.fetchAndCacheSettings(alignmentId),
        ),
      );
  }

  private fetchAndCacheSettings(alignmentId: string) {
    return this.alignmentExportApiService.getTrimBimSettings(alignmentId).pipe(
      switchMap((settings) => {
        return this.store
          .dispatch(new CacheDisplaySetting(alignmentId, settings))
          .pipe(map(() => settings));
      }),
    );
  }

  public updateDisplaySettings(
    alignmentId: string,
    settings: TrimBimExportArgs,
  ): Observable<TrimBimExportArgs> {
    return this.alignmentExportApiService.updateTrimBimSettings(alignmentId, settings).pipe(
      switchMap(() =>
        this.store.dispatch([
          new CacheDisplaySetting(alignmentId, settings),
          new ClearNavigationState(alignmentId), // Clear navigation state as it dependends on display settings
        ]),
      ),
      map(() => settings),
    );
  }
}
