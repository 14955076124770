import { Lookup } from './types';

/**
 * Removes the specified items from the array if it exists
 * @param source The source array to remove the item(s) from
 * @param itemsToRemove The item(s) to remove
 * @param comparer The comparer to use for matching items. If not specified indexOf is used
 * @param cloneSource Indicates of the source array should be cloned, resulting in a copy with removed items
 */
export function arrayRemove<T>(
  source: T[],
  itemsToRemove: T | T[],
  comparer?: (left: T, right: T) => boolean,
  cloneSource = false,
): T[] {
  const toRemove = Array.isArray(itemsToRemove) ? itemsToRemove : [itemsToRemove];
  const removeFrom = cloneSource ? [...source] : source;
  toRemove.forEach((removeItem) => {
    const index = comparer
      ? removeFrom.findIndex((sourceItem) => comparer(sourceItem, removeItem))
      : removeFrom.indexOf(removeItem);
    if (index !== -1) {
      removeFrom.splice(index, 1);
    }
  });

  return removeFrom;
}

/**
 * Replaces the specified item in the array if it exists
 * @param source The source array to replace the item in
 * @param itemToReplace The item to be replaced
 * @param replacement The item to replace with
 * @param comparer The comparer to use for matching items. If not specified indexOf is used
 */
export function arrayReplace<T>(
  source: T[],
  itemToReplace: T,
  replacement: T,
  comparer?: (left: T, right: T) => boolean,
): void {
  const index = comparer
    ? source.findIndex((sourceItem) => comparer(sourceItem, itemToReplace))
    : source.indexOf(itemToReplace);
  if (index >= 0) {
    source.splice(index, 1, replacement);
  }
}

/**
 * Sorts the object array using the selector value
 * @param source The array to sort
 * @param valueSelector The value to use for sorting. If not specified, uses the item
 */
export function arraySort<T, V = T>(
  source: T[],
  valueSelector?: (item: T) => V,
  descending = false,
): T[] {
  const sortedArray = source.sort((a, b) => {
    const valueA = valueSelector ? valueSelector(a) : a;
    const valueB = valueSelector ? valueSelector(b) : b;

    let result = 0;
    if (valueA > valueB) {
      result = 1;
    } else if (valueA < valueB) {
      result = -1;
    }

    // Reverse order if specified
    if (descending) {
      return result * -1;
    } else {
      return result;
    }
  });

  return sortedArray;
}

/**
 * Groups an array of objects into a lookup by the specified key
 * @param source The source array to create a lookup from
 * @param keySelector The key to use for the entry
 * @param itemSelector The item to use for the entry
 */
export function arrayToLookup<T, K extends string | number>(
  array: T[],
  keySelector: (item: T, index: number) => K,
): Lookup<K, T>;
export function arrayToLookup<T, K extends string | number, R>(
  array: T[],
  keySelector: (item: T, index: number) => K,
  itemSelector: (item: T, index: number) => R,
): Lookup<K, R>;
export function arrayToLookup<T, K extends string | number, R>(
  array: T[],
  keySelector: (item: T, index: number) => K,
  itemSelector?: (item: T, index: number) => R,
): Lookup<K, R> {
  const lookup = {} as Lookup<K, R>;
  array.forEach((item, index) => {
    const key = keySelector(item, index);

    // Set or overwrite existing
    if (itemSelector) {
      lookup[key] = itemSelector(item, index);
    } else {
      lookup[key] = item as never;
    }
  });

  return lookup;
}

/**
 * Gets the difference between two arrays, optionaly using the specified key
 * @param leftArray The first array to filter
 * @param rightArray The second array to match on
 * @param valueSelector The value to use for comparison. If not specified, uses the item
 */
export function arrayIntersect<T, V = T>(
  leftArray: T[],
  rightArray: T[],
  valueSelector?: (item: T) => V,
): T[] {
  return arrayIndexCompare(leftArray, rightArray, (index) => index >= 0, valueSelector);
}

/**
 * Gets the difference between two arrays, optionaly using the specified key
 * @param leftArray The first array to filter
 * @param rightArray The second array to match on
 * @param valueSelector The value to use for comparison. If not specified, uses the item
 */
export function arrayExcept<T, V = T>(
  leftArray: T[],
  rightArray: T[],
  valueSelector?: (item: T) => V,
): T[] {
  return arrayIndexCompare(leftArray, rightArray, (index) => index < 0, valueSelector);
}

/**
 * Compare two arrays using the specified index comparer predicate, optionally based on the selected value
 * @param leftArray The first array to filter
 * @param rightArray The second array to match on
 * @param comparer The comparer to use for matching items. If not specified indexOf is used
 * @param comparer The index comparision predictate
 * @param valueSelector The value to use for comparison. If not specified, uses the item
 */
function arrayIndexCompare<T, V = T>(
  leftArray: T[],
  rightArray: T[],
  comparer: (index: number) => boolean,
  valueSelector?: (item: T) => V,
): T[] {
  // If empty filter array, no need to traverse
  if (rightArray.length === 0) {
    return leftArray.slice();
  }

  // Filter array
  const result = leftArray.filter((leftItem) => {
    if (valueSelector) {
      // Compare using value
      const leftValue = valueSelector(leftItem);
      const index = rightArray.findIndex((rightItem) => leftValue === valueSelector(rightItem));
      return comparer(index);
    } else {
      // Compare items
      const index = rightArray.indexOf(leftItem);
      return comparer(index);
    }
  });

  return result;
}
