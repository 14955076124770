import { FactoryProvider, InjectionToken } from '@angular/core';

function windowFactory(): Window | object {
  return globalThis;
}

export const WINDOW = new InjectionToken('WindowToken');

export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
};
