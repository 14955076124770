import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchHttpError } from '../../shared';
import { AppState } from '../../state';
import { UnitType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UnitOfMeasureApiService {
  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  private getUrl(relativeUrl = ''): string {
    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const connectProject = this.store.selectSnapshot(AppState.project);
    const url = `${baseUrl}/uom/${connectProject.location}/${relativeUrl}`;
    return url;
  }

  /** Get all unit types */
  public getUnitTypes(): Observable<UnitType[]> {
    const url = this.getUrl();
    return this.http.get<UnitType[]>(url).pipe(catchHttpError());
  }
}
