import { AlignmentProjection, Measurement } from '../../api';

export enum MeasurementDisplayStatus {
  Hidden,
  Visible,
}

export interface MeasurementViewModel extends Measurement {
  state: MeasurementDisplayStatus;
  deleting: boolean;
  markupIds?: number[];
  projection: AlignmentProjection;
}

export function mapMeasurement(measurement: Measurement): MeasurementViewModel {
  const viewModel: MeasurementViewModel = {
    ...measurement,
    state: MeasurementDisplayStatus.Hidden,
    deleting: false,
  };
  return viewModel;
}
