import { NavigationArgs, StationPositions } from './navigation.view-models';

export class CacheStationPositions {
  static readonly type = '[Navigation] CacheStationPositions';
  constructor(
    public readonly alignmentId: string,
    public readonly stationPositions: StationPositions,
  ) {}
}

export class CacheNavigationArgs {
  static readonly type = '[Navigation] CacheNavigationArgs';
  constructor(
    public readonly alignmentId: string,
    public readonly navigationArgs: NavigationArgs,
  ) {}
}

export class ClearNavigationState {
  static readonly type = '[Navigation] ClearNavigationState';
  constructor(public readonly alignmentId: string) {}
}
