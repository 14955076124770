<!-- Header -->
<div class="flex flex-row justify-end mb-2">
  <!-- Camera toggle -->
  <mat-button-toggle-group [formControl]="cameraControl" hideSingleSelectionIndicator="true">
    @for (camera of cameras; track $index) {
      <mat-button-toggle
        [value]="camera"
        [modus-tooltip]="camera.label"
        [tooltipClass]="camera.tooltipClass ?? ''"
        [disabled]="loading"
      >
        <md-icon>{{ camera.icon }}</md-icon>
      </mat-button-toggle>
    }
  </mat-button-toggle-group>
</div>

<!-- Loader -->
@if (!alignment) {
  <div class="flex flex-col grow items-center justify-center py-12">
    <div class="text-white bg-red-light rounded-sm p-2 text-sm">
      <div class="font-bold">No alignment selected!</div>
    </div>
  </div>
} @else {
  <!-- Detail -->
  <div class="flex flex-col grow">
    <!-- Free-view -->
    @if (this.selectedCamera === AlignmentCameras.Free) {
      <div class="flex flex-col grow items-center justify-center py-12">
        <div class="text-white bg-yellow-light rounded-sm p-2 text-sm">
          <div class="font-bold">
            Alignment camera controls disabled. Please select an alignment camera mode!
          </div>
        </div>
      </div>
    } @else {
      <!-- Camera Controls -->
      <!-- Loader -->
      @if (!stationPositions) {
        <div class="flex flex-col grow items-center justify-center py-12">
          <nzc-busy-indicator [busy]="loading">
            <div class="text-white bg-red-light rounded-sm p-2 text-sm">
              <div class="font-bold">Could not load navigation data!</div>
            </div>
          </nzc-busy-indicator>
        </div>
      } @else {
        <!-- Detail -->
        <form [formGroup]="formGroup">
          <modus-form-field class="modus-form-field-type-slider">
            <modus-label class="flex flex-row">
              <div class="grow">Height</div>
              <div class="font-semibold">
                {{ formGroup.value.cameraHeight | uom }}
              </div>
            </modus-label>
            <mat-slider [min]="-25" [max]="25" [step]="selectedCamera.step">
              <input modus-input matSliderThumb [formControl]="formGroup.controls.cameraHeight" />
            </mat-slider>
          </modus-form-field>

          @if (selectedCamera === AlignmentCameras.Attached) {
            <modus-form-field class="modus-form-field-type-slider">
              <modus-label class="flex flex-row">
                <div class="grow">Offset</div>
                <div class="font-semibold">
                  {{ formGroup.value.cameraOffset | uom }}
                </div>
              </modus-label>
              <mat-slider [min]="-50" [max]="50" [step]="selectedCamera.step">
                <input modus-input matSliderThumb [formControl]="formGroup.controls.cameraOffset" />
              </mat-slider>
            </modus-form-field>
          }

          <modus-form-field class="modus-form-field-type-slider">
            <modus-label class="flex flex-row">
              <div class="grow">Distance</div>
              <div class="font-semibold">
                {{ formGroup.value.cameraDistance | uom }}
              </div>
            </modus-label>
            <mat-slider [min]="1" [max]="100" [step]="selectedCamera.step">
              <input modus-input matSliderThumb [formControl]="formGroup.controls.cameraDistance" />
            </mat-slider>
          </modus-form-field>

          <modus-form-field class="modus-form-field-type-slider">
            <modus-label class="flex flex-row">
              <div class="grow">Navigate Along Alignment</div>
              <div class="font-semibold">{{ formGroup.value.chainage | uom }}</div>
            </modus-label>
            <mat-slider [min]="sliderStart" [max]="sliderEnd" [step]="jumpIncrement">
              <input modus-input matSliderThumb [formControl]="formGroup.controls.stationIndex" />
            </mat-slider>
          </modus-form-field>

          <modus-form-field>
            <modus-label>Jump To Chainage</modus-label>
            <input
              modus-input
              [formControl]="formGroup.controls.chainage"
              (keydown.enter)="updateChainage($event)"
              class="text-right"
            />
            <div modus-text-suffix>m</div>
          </modus-form-field>

          <modus-form-field class="modus-form-field-type-checkbox mt-2">
            <modus-label class="flex flex-row items-center">
              <div class="grow">Reverse Direction</div>
              <modus-checkbox [formControl]="formGroup.controls.reverse"></modus-checkbox>
            </modus-label>
            <!-- TODO: Fix - remove dummy hidden input to prevent ModusFormField ngControl error -->
            <input modus-input [formControl]="formGroup.controls.reverse" class="hidden" />
          </modus-form-field>

          @if (selectedCamera === AlignmentCameras.Trolley) {
            <modus-form-field class="modus-form-field-type-checkbox">
              <modus-label class="flex flex-row items-center">
                <div class="grow">Lock Look-at Direction</div>
                <modus-checkbox [formControl]="formGroup.controls.lockLookAt"></modus-checkbox>
              </modus-label>
              <!-- TODO: Fix - remove dummy hidden input to prevent ModusFormField ngControl error -->
              <input modus-input [formControl]="formGroup.controls.lockLookAt" class="hidden" />
            </modus-form-field>
          }
        </form>
      }
    }
  </div>
}
