const e = {
    postMessage: (e, n) => {
      const t = JSON.stringify(e);
      connectWsApiIntegrator.postMessage(t);
    }
  },
  n = {
    postMessage: (e, n) => {
      window.chrome.webview.postMessage(e);
    }
  },
  t = () => crypto.randomUUID(),
  i = () => {
    const e = window;
    return !!(e && e.chrome && e.chrome.webview);
  },
  r = () => {
    const e = window;
    return !(!e || !("CefSharp" in e));
  },
  o = async () => (window.connectWsApiIntegrator || (await CefSharp.BindObjectAsync("connectWsApiIntegrator")), e),
  s = () => n,
  c = async e => {
    let n;
    return n = e === window ? r() ? await o() : i() ? s() : e : e, n;
  },
  a = "Trimble.dispatcher.v1",
  u = {},
  f = {};
let d = 1;
function p(e) {
  const n = h();
  return f[n] = e, () => delete f[n];
}
function l(e, n, t, i, r = 3e5) {
  return new Promise((o, s) => {
    const c = h(),
      f = {
        scope: a,
        type: "request",
        id: c,
        api: t,
        args: i
      };
    let d;
    r > 0 && (d = setTimeout(() => {
      delete u[c], s(new Error("dispatcher.ts | sendRequest(): Operation timed out."));
    }, r)), u[c] = e => {
      delete u[c], d && clearTimeout(d), e.error ? s(e.error) : o(e.result);
    }, e.postMessage(f, n);
  });
}
function w(e, n, t, i) {
  const r = {
    scope: a,
    type: "event",
    event: t,
    data: i
  };
  c(e).then(e => {
    e.postMessage(r, n);
  }).catch(e => {
    console.error("sendEvent() call failed", e);
  });
}
async function g(e) {
  const n = "null" === e.origin ? "*" : e.origin,
    t = e.data;
  if (function (e) {
    return y(e) && "event" === e.type;
  }(t)) for (const i in f) {
    if (!f.hasOwnProperty(i)) continue;
    const r = f[i].event;
    if (r) {
      r(e.source, n, t.event, t.data);
    }
  } else if (function (e) {
    return y(e) && "request" === e.type;
  }(t)) {
    const i = await c(e.source);
    let r;
    for (const e in f) {
      if (!f.hasOwnProperty(e)) continue;
      const o = f[e].request;
      if (o && !r) {
        const e = o(i, n, t.api, t.args);
        if (void 0 !== e) try {
          r = {
            scope: a,
            type: "response",
            id: t.id,
            api: t.api,
            result: await e
          };
        } catch (e) {
          r = {
            scope: a,
            type: "response",
            id: t.id,
            api: t.api,
            error: String(e)
          };
        }
      }
    }
    r || (r = {
      scope: a,
      type: "response",
      id: t.id,
      api: t.api,
      error: "Not supported"
    }), i.postMessage(r, n);
  } else if (function (e) {
    return y(e) && "response" === e.type;
  }(t)) {
    const e = u[t.id];
    e && e(t);
  }
}
function y(e) {
  return !!e && e.scope === a;
}
function h() {
  return d++;
}
const m = {},
  v = [];
let b;
const M = new Promise(e => {
  b = () => {
    b = () => {}, e();
  };
});
let S;
function P(e) {
  if ("object" != typeof e) throw new Error("Api must be an object");
  T(m, e), b();
}
function O(e) {
  const n = v.find(n => n.dispatcher === e.dispatcher);
  if (n) return n.identifier = e.identifier || n.identifier, n.origin = e.origin || n.origin, n;
  if (e.dispatcher) {
    if (e.identifier) {
      if (v.find(n => n.identifier === e.identifier)) throw new Error(`[Workspace API] Client with identifier '${e.identifier}' already registered!`);
    } else e.identifier = t();
    return v.push(e), e;
  }
}
function _(e) {
  const n = v.findIndex(n => n.dispatcher === e.dispatcher);
  -1 !== n && v.splice(n, 1);
}
const E = (e, n, t, i) => {
  if (t.origin) {
    let r;
    r = t.identifier && i && t.identifier === i ? Object.assign(Object.assign({}, n), {
      origin: {
        isSelf: !0
      }
    }) : n, w(t.dispatcher, t.origin, e, r);
  }
};
function j(e, n, t, i) {
  if (t && t.identifier) {
    const r = v.find(e => e.identifier === t.identifier);
    r && E(e, n, r, i);
  } else for (const t of v) E(e, n, t, i);
}
function k() {
  try {
    return window.self !== window.top || r() || i();
  } catch (e) {
    return !0;
  }
}
function R(e, n) {
  return "function" == typeof e && "function" == typeof n && e.name === n.name || "string" == typeof e && "string" == typeof n || "number" == typeof e && "number" == typeof n;
}
function T(e, n) {
  for (const t in n) {
    const i = n[t];
    if (i) if (t in e) {
      const n = e[t];
      if ("object" == typeof i && "object" == typeof n) T(n, i);else {
        if (!R(i, n)) throw new Error(`Cannot merge ${t} (${n} and ${i})`);
        e[t] = i;
      }
    } else switch (typeof i) {
      case "object":
        {
          const n = {};
          T(n, i), e[t] = n;
          break;
        }
      default:
        e[t] = i;
    }
  }
}
function V(e, n, t) {
  const i = {};
  for (const r in e) {
    const o = e[r];
    if ((void 0 !== t || "onConnect" !== r && "onRequest" !== r) && o) if ("object" == typeof o) {
      const e = t && t + "." + String(r) || String(r);
      i[r] = V(o, n, e);
    } else i[r] = n(r, e[r], t);
  }
  return i;
}
var W, A;
p({
  request: (e, n, i, r) => {
    if (".connect_api_client_v1" === i) return M.then(() => {
      let i = v.find(n => n.dispatcher === e);
      if (i) i.origin = n;else {
        if (!e) return;
        i = {
          dispatcher: e,
          origin: n,
          identifier: t()
        }, v.push(i);
      }
      var r;
      (r = m) && "function" == typeof r.onConnect && m.onConnect(i);
      return V(m, (e, n) => "function" == typeof n ? ".api_function_v1" : n);
    });
    {
      const t = v.find(t => t.dispatcher === e && t.origin === n);
      if (t) {
        if ((o = m) && "function" == typeof o.onRequest) {
          const e = m.onRequest(t, i, r);
          if (void 0 !== e) return e;
        }
        const e = function (e, n) {
          const t = n.split(".");
          let i = e;
          for (const e of t) i = "object" == typeof i && i && e in i ? i[e] : void 0;
          return i;
        }(m, i);
        if ("function" == typeof e) {
          const n = e.apply(void 0, r);
          return void 0 === n ? Promise.resolve(n) : n;
        }
        return Promise.resolve(e);
      }
      return;
    }
    var o;
  }
}), function (e) {
  e[e.LengthMeasure = 0] = "LengthMeasure", e[e.AreaMeasure = 1] = "AreaMeasure", e[e.VolumeMeasure = 2] = "VolumeMeasure", e[e.MassMeasure = 3] = "MassMeasure", e[e.AngleMeasure = 4] = "AngleMeasure", e[e.StringValue = 5] = "StringValue", e[e.IntValue = 6] = "IntValue", e[e.DoubleValue = 7] = "DoubleValue", e[e.DateTime = 8] = "DateTime", e[e.Logical = 9] = "Logical", e[e.Boolean = 10] = "Boolean";
}(W || (W = {})), function (e) {
  e[e.Selected = 1] = "Selected", e[e.Hidden = 4] = "Hidden", e[e.SelectedHidden = 5] = "SelectedHidden", e[e.Visible = 6] = "Visible", e[e.SelectedVisible = 7] = "SelectedVisible", e[e.Highlighted = 8] = "Highlighted";
}(A || (A = {}));
const q = ["reset", "selection", "areaSelection", "measurement", "pointMarkup", "cloudMarkup", "arrowMarkup", "lineMarkup", "textMarkup", "freelineMarkup", "clipPlane", "verticalClipPlane", "picking"],
  L = ["edge", "point", "surface"];
var C, I;
function N(e = "prod") {
  return `https://${{
    int: "web.int",
    qa: "web.qa",
    stage: "web.stage",
    prod: "web"
  }[e]}.connect.trimble.com?isEmbedded=true`;
}
function D(e, n, t) {
  return window.removeEventListener("message", $), window.addEventListener("message", $), S && S(), function (e, n, t = 1e4) {
    async function c(e, n) {
      const i = await l(e, n, ".connect_api_client_v1", [], 0);
      if (i && "object" == typeof i) return V(i, (i, r, o) => {
        if (".api_function_v1" === r) {
          const r = o ? o + "." + String(i) : String(i);
          return (...i) => l(e, n, r, i, t);
        }
        return r;
      });
      throw new Error("Failed to connect");
    }
    function a(e) {
      if (!e) return "*";
      try {
        return new URL(e).origin;
      } catch (e) {
        return "*";
      }
    }
    if (n && (S = p({
      event: (e, t, i, r) => n(i, r)
    })), e === window) return 0 !== Object.keys(m).length ? Promise.resolve(m) : r() ? new Promise(async e => {
      const n = await o();
      e(await c(n, "*"));
    }) : i() ? c(s(), "*") : Promise.resolve(m);
    if ((u = e) && "function" == typeof u.postMessage) return c(e, "*");
    if ((e => e && "object" == typeof e.contentWindow)(e)) {
      const n = [];
      return n.push(new Promise((n, t) => {
        const i = async () => {
          e.removeEventListener("load", i), e.contentWindow ? n(await c(e.contentWindow, a(e.src))) : t(new Error("Cannot access the target content window"));
        };
        e.addEventListener("load", i);
      })), e.contentWindow && n.push(c(e.contentWindow, a(e.src))), Promise.race(n);
    }
    return Promise.reject(new Error("Target must be a window or an iframe"));
    var u;
  }(e, n, t);
}
function H(e) {
  return P(e);
}
function U(e, n, t, i) {
  w(e, n, t, i);
}
function $(e) {
  return g(e);
}
!function (e) {
  e.LookAround = "look_around", e.Pan = "pan", e.Panorama = "panorama", e.Rotate = "rotate", e.Walk = "walk";
}(C || (C = {})), function (e) {
  e[e.SORT_NONE = 0] = "SORT_NONE", e[e.SORT_UP = 1] = "SORT_UP", e[e.SORT_DOWN = -1] = "SORT_DOWN";
}(I || (I = {}));
export { C as CameraMode, W as PropertyType, I as SortDirection, q as TOOLS, L as TOOL_SNAP_TYPES, A as ViewEntityStates, D as connect, $ as dispatcherEventListener, H as expose, N as getConnectEmbedUrl, k as isApplicationEmbedded, O as preregister, _ as removeClient, U as sendEvent, j as sendEventToAllClients };