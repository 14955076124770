import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, of, switchMap } from 'rxjs';
import { AlignmentCalculationsApiService } from '../../api';
import { arrayToLookup, Lookup } from '../../shared';
import { DisplaySettingService } from '../display-setting';
import { CacheNavigationArgs, CacheStationPositions } from './navigation.actions';
import { NavigationState } from './navigation.state';
import {
  defaultNavigationArgs,
  NavigationArgs,
  StationPositionComponents,
  StationPositions,
} from './navigation.view-models';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly stationInterval = 1;

  constructor(
    private store: Store,
    private displaySettingService: DisplaySettingService,
    private calculationsService: AlignmentCalculationsApiService,
  ) {}

  public getStationPositions(alignmentId: string): Observable<StationPositions> {
    return this.store
      .selectOnce(NavigationState.getStationPositions(alignmentId))
      .pipe(
        switchMap((stationPositions) =>
          stationPositions ? of(stationPositions) : this.fetchAndCacheStationPositions(alignmentId),
        ),
      );
  }

  private fetchAndCacheStationPositions(alignmentId: string): Observable<StationPositions> {
    return this.displaySettingService.getDisplaySettings(alignmentId).pipe(
      switchMap((settings) => {
        return this.calculationsService.transformStations(alignmentId, {
          height: 0,
          offset: 0,
          segmentLength: this.stationInterval,
          beginStation: settings.beginStation,
          endStation: settings.endStation,
        });
      }),
      map((rawStationPositions) => {
        // Create component index by name lookup
        const componentIndex: Lookup<StationPositionComponents, number> = arrayToLookup(
          rawStationPositions.components,
          (c) => c.name,
          (c, i) => i + 1, // Adjust as component values start at index 1
        );

        // Create station positions with coordinates lookup
        const stationPositions: StationPositions = {
          ...rawStationPositions,
          coordinates: arrayToLookup(
            rawStationPositions.values,
            (v, i) => i,
            (values) => ({
              x: values[componentIndex['X']],
              y: values[componentIndex['Y']],
              z: values[componentIndex['Z']],
            }),
          ),
          directions: arrayToLookup(
            rawStationPositions.values,
            (v, i) => i,
            (values) => ({
              forward: {
                x: values[componentIndex['ForwardX']],
                y: values[componentIndex['ForwardY']],
                z: values[componentIndex['ForwardZ']],
              },
              up: {
                x: values[componentIndex['UpX']],
                y: values[componentIndex['UpY']],
                z: values[componentIndex['UpZ']],
              },
              right: {
                x: values[componentIndex['RightX']],
                y: values[componentIndex['RightY']],
                z: values[componentIndex['RightZ']],
              },
            }),
          ),
        };

        return stationPositions;
      }),
      switchMap((stationPositions) => {
        return this.store
          .dispatch(new CacheStationPositions(alignmentId, stationPositions))
          .pipe(map(() => stationPositions));
      }),
    );
  }

  public getNavigationArgs(alignmentId: string): Observable<NavigationArgs> {
    return this.store
      .selectOnce(NavigationState.getNavigationArgs(alignmentId))
      .pipe(
        switchMap((navigationArgs) =>
          navigationArgs ? of(navigationArgs) : this.createAndCacheNavigationArgs(alignmentId),
        ),
      );
  }

  private createAndCacheNavigationArgs(alignmentId: string) {
    const navigationArgs = { ...defaultNavigationArgs };
    return this.store
      .dispatch(new CacheNavigationArgs(alignmentId, navigationArgs))
      .pipe(map(() => navigationArgs));
  }

  public updateNavigationArgs(alignmentId: string, navigationArgs: NavigationArgs): void {
    this.store
      .dispatch(new CacheNavigationArgs(alignmentId, navigationArgs))
      .pipe(map(() => navigationArgs));
  }
}
