import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { EMPTY, Observable, map, of, switchMap } from 'rxjs';
import { ViewSpec } from 'trimble-connect-workspace-api';
import { SettingApiService } from '../api';
import {
  AlignmentState,
  CacheNavigationArgs,
  HideAlignment,
  NavigationArgs,
  NavigationState,
  SetActiveAlignment,
  ShowAlignment,
} from '../state';

interface SavedAlignment {
  id: string;
  navigationArgs?: NavigationArgs;
}

interface SavedView {
  connectViewId: string;
  alignments: SavedAlignment[];
}

@Injectable({ providedIn: 'root' })
export class ViewService {
  private readonly SettingPreFix = 'view_';

  constructor(
    private settingService: SettingApiService,
    private store: Store,
  ) {}

  private getSettingKey(view: ViewSpec): string {
    return this.SettingPreFix + view.id;
  }

  public createView(view: ViewSpec): Observable<unknown> {
    if (isNil(view.id)) return EMPTY;

    const key = this.getSettingKey(view);
    return this.createViewSetting(view.id).pipe(
      switchMap((viewSetting) => this.settingService.createOrUpdateSetting(key, viewSetting)),
    );
  }

  public updateView(view: ViewSpec): Observable<unknown> {
    if (isNil(view.id)) return EMPTY;

    const key = this.getSettingKey(view);
    return this.createViewSetting(key).pipe(
      switchMap((connectView) => this.settingService.createOrUpdateSetting(key, connectView)),
    );
  }

  public removeView(view: ViewSpec): Observable<unknown> {
    if (isNil(view.id)) return EMPTY;

    const key = this.getSettingKey(view);
    return this.settingService.deleteSetting(key);
  }

  public setView(view: ViewSpec): Observable<unknown> {
    if (isNil(view.id)) return EMPTY;

    const key = this.getSettingKey(view);
    return this.settingService.getSetting<SavedView>(key).pipe(
      map((setting) => setting.value),
      switchMap((view) => this.deactivateAlignment(view)),
      switchMap((view) => this.hideNonViewAlignments(view)),
      switchMap((view) => this.showViewAlignments(view)),
      switchMap((view) => this.activateViewAlignment(view)),
    );
  }

  private createViewSetting(viewId: string): Observable<SavedView> {
    const displayedAlignments = this.store
      .selectSnapshot(AlignmentState.alignments)
      .filter((alignment) => alignment.show);

    const viewSetting: SavedView = {
      connectViewId: viewId,
      alignments: displayedAlignments.map((da) => {
        const savedAlignment: SavedAlignment = {
          id: da.id,
          navigationArgs: da.active
            ? this.store.selectSnapshot(NavigationState.getNavigationArgs(da.id))
            : undefined,
        };
        return savedAlignment;
      }),
    };

    return of(viewSetting);
  }

  private hideNonViewAlignments(view: SavedView): Observable<SavedView> {
    const savedAlignments = view.alignments.map((alignment) => alignment.id);
    const alignmentsToHide = this.store
      .selectSnapshot(AlignmentState.alignments)
      .filter((alignment) => alignment.show && !savedAlignments.includes(alignment.id));

    if (alignmentsToHide.length === 0) {
      return of(view);
    }

    const hideActions = alignmentsToHide.map((alignment) => new HideAlignment(alignment.id));
    return this.store.dispatch(hideActions).pipe(map(() => view));
  }

  private showViewAlignments(view: SavedView): Observable<SavedView> {
    const savedAlignments = view.alignments.map((alignment) => alignment.id);
    const alignmentsToShow = this.store
      .selectSnapshot(AlignmentState.alignments)
      .filter((alignment) => !alignment.show && savedAlignments.includes(alignment.id));

    if (alignmentsToShow.length === 0) {
      return of(view);
    }

    const showActions = alignmentsToShow.map((alignment) => new ShowAlignment(alignment.id, false));
    return this.store.dispatch(showActions).pipe(map(() => view));
  }

  private deactivateAlignment(view: SavedView): Observable<SavedView> {
    return this.store.dispatch(new SetActiveAlignment(undefined)).pipe(map(() => view));
  }

  private activateViewAlignment(view: SavedView): Observable<SavedView> {
    const activeAlignment = view.alignments.find((alignment) =>
      isDefined(alignment.navigationArgs),
    );

    if (isNil(activeAlignment)) {
      return of(view);
    }

    // Deactivate the current active alignment
    return of(view).pipe(
      // Cache navigation args to be used when the alignment is activated
      switchMap(() => {
        return isDefined(activeAlignment.navigationArgs)
          ? this.store.dispatch(
              new CacheNavigationArgs(activeAlignment.id, activeAlignment.navigationArgs),
            )
          : EMPTY;
      }),
      // Activate the alignment
      switchMap(() => this.store.dispatch(new SetActiveAlignment(activeAlignment.id))),
      // Return the view
      map(() => view),
    );
  }
}
