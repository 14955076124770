import { AlignmentViewModel } from './alignment.view-models';

export class SetAlignmentsIsLoading {
  static readonly type = '[Alignments] SetAlignmentsIsLoading';
  constructor(public readonly isLoading: boolean) {}
}

export class SetAlignmentsLoadError {
  static readonly type = '[Alignments] SetAlignmentsLoadError';
  constructor(public readonly error: string | undefined) {}
}

export class SetAlignments {
  static readonly type = '[Alignments] SetAlignments';
  constructor(public readonly alignments: AlignmentViewModel[]) {}
}

export class SetActiveAlignment {
  static readonly type = '[Alignments] SetActiveAlignment';
  constructor(public readonly alignmentId: string | undefined) {}
}

export class AddAlignments {
  static readonly type = '[Alignments] AddAlignments';
  constructor(public readonly alignments: AlignmentViewModel[]) {}
}

export class UpdateAlignment {
  static readonly type = '[Alignments] UpdateAlignment';
  constructor(
    public readonly alignmentId: string,
    public readonly update: Partial<AlignmentViewModel>,
  ) {}
}

export class RemoveAlignment {
  static readonly type = '[Alignments] RemoveAlignment';
  constructor(public readonly alignmentId: string) {}
}

export class ShowAlignment {
  static readonly type = '[Alignments] ShowAlignment';
  constructor(
    public readonly alignmentId: string,
    public readonly fitToView: boolean,
  ) {}
}

export class HideAlignment {
  static readonly type = '[Alignments] HideAlignment';
  constructor(public readonly alignmentId: string) {}
}

export class ReloadAlignment {
  static readonly type = '[Alignments] ReloadAlignment';
  constructor(public readonly alignmentId: string) {}
}
