import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusMenuModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  switchMap,
  take,
} from 'rxjs';
import { ViewAction, ViewActionEventArgument } from 'trimble-connect-workspace-api';
import { AlignmentSourceFileState } from '../../api';
import { BusyIndicatorComponent, OverlayPosition, OverlayService } from '../../shared';
import {
  AlignmentListFilterOption,
  AlignmentLoadState,
  AlignmentService,
  AlignmentState,
  AlignmentViewModel,
  AppState,
  ClearNavigationState,
  HideAlignment,
  ReloadAlignment,
  SetActiveAlignment,
  ShowAlignment,
  SourceFileService,
  SourceFileState,
  SourceFileViewModel,
  UpdateAlignment,
} from '../../state';
import { AlignmentNavigationComponent } from '../alignment-navigation/alignment-navigation.component';
import { AlignmentTrimbimSettingsComponent } from '../alignment-trimbim-settings/alignment-trimbim-settings.component';
import { MeasurementListComponent } from '../measurement-list/measurement-list.component';
import { ViewService } from '../view.service';

@UntilDestroy()
@Component({
  selector: 'nzc-alignment-viewer-panel',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    ModusButtonModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusMenuModule,
    ModusTooltipModule,
    BusyIndicatorComponent,
    AlignmentTrimbimSettingsComponent,
    AlignmentNavigationComponent,
    MeasurementListComponent,
  ],
  templateUrl: './alignment-viewer-panel.component.html',
})
export class AlignmentViewerPanelComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentClasses = 'flex flex-col grow';

  private readonly workspace = this.store.selectSnapshot(AppState.workspace);

  public readonly AlignmentLoadState = AlignmentLoadState;
  public readonly SourceFileState = AlignmentSourceFileState;
  public readonly AlignmentListFilterOption = AlignmentListFilterOption;

  private readonly alignmentsLoading$ = this.store.select(AlignmentState.isLoading);
  private readonly alignmentLoadingError$ = this.store.select(AlignmentState.loadingError);
  private readonly alignments$ = this.store.select(AlignmentState.alignments);
  private readonly selectedAlignment$ = this.store.select(AlignmentState.selected);

  private readonly sourceFilesLoading$ = this.store.select(SourceFileState.isLoading);
  private readonly sourceFilesLoadingError$ = this.store.select(SourceFileState.loadingError);
  private readonly sourceFiles$ = this.store.select(SourceFileState.sourceFiles);

  private readonly loading$ = combineLatest([
    this.alignmentsLoading$,
    this.sourceFilesLoading$,
  ]).pipe(map(([a, b]) => a || b));

  private readonly loadingError$ = combineLatest([
    this.alignmentLoadingError$,
    this.sourceFilesLoadingError$,
  ]).pipe(map(([a, b]) => a || b));

  public readonly loading = toSignal(this.loading$, { initialValue: true });
  public readonly loadingError = toSignal(this.loadingError$);
  public readonly alignments = toSignal(this.alignments$, { initialValue: [] });
  public readonly selectedAlignment = toSignal(this.selectedAlignment$);
  public readonly sourceFiles = toSignal(this.sourceFiles$, { initialValue: [] });

  public filterOptionControl = new FormControl<AlignmentListFilterOption>(
    AlignmentListFilterOption.ShowAll,
  );

  constructor(
    private alignmentService: AlignmentService,
    private sourceFileService: SourceFileService,
    private overlayService: OverlayService,
    private store: Store,
    private actions$: Actions,
    private viewService: ViewService,
  ) {
    // TODO: Implement filter selection display
  }

  public ngOnInit(): void {
    this.subscribeToShowHideAlignments();
    this.subscribeToViewEvent();
  }

  public ngOnDestroy(): void {
    this.removeAlignmentsFromViewer();
  }

  @HostListener('window:beforeunload')
  public windowUnload() {
    this.removeAlignmentsFromViewer();
  }

  private subscribeToViewEvent() {
    this.workspace.event$
      .pipe(
        filter((event) => event.id === 'view.onViewAction'),
        map((event) => event.arg as ViewActionEventArgument),
        switchMap((viewAction) =>
          this.getViewAction$(viewAction.data).pipe(
            take(1),
            // Catch errors keep subscription alive
            catchError((err) => {
              console.error(`View action '${viewAction.data.action}' failed`, viewAction, err);
              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToShowHideAlignments() {
    // Subscribe to show alignment actions
    this.actions$.pipe(ofActionDispatched(ShowAlignment), untilDestroyed(this)).subscribe({
      next: async (action) => {
        await this.addTrimBim(action.alignmentId, action.fitToView);
      },
    });

    // Subscribe to hide alignment actions
    this.actions$.pipe(ofActionDispatched(HideAlignment), untilDestroyed(this)).subscribe({
      next: async (action) => {
        await this.removeTrimBim(action.alignmentId);
      },
    });

    // Subscribe to reload alignment actions
    this.actions$.pipe(ofActionDispatched(ReloadAlignment), untilDestroyed(this)).subscribe({
      next: async (action) => {
        // Note: We don't call 'this.removeTrimBim' on reload as 'viewer.addTrimbimModel' take care of
        // removing existing model. This results in the shortest visual delay when reloading.
        await this.addTrimBim(action.alignmentId, false);
      },
    });

    // Subscribe to project settings changes (e.g. UoM changes)
    this.store
      .select(AppState.projectSettings)
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        // Reload all shown alignments
        const reloadAlignments = this.alignments()
          .filter((a) => a.show)
          .map((a) => this.addTrimBim(a.id, false));

        await Promise.all(reloadAlignments);
      });
  }

  private removeAlignmentsFromViewer(): void {
    // Remove shown alignments
    const hideActions = this.alignments()
      .filter((a) => a.show)
      .map((a) => new HideAlignment(a.id));
    this.store.dispatch(hideActions);
  }

  public refresh(): void {
    this.removeAlignmentsFromViewer();
    this.sourceFileService.loadSourceFiles();
    this.alignmentService.loadAligments();
  }

  public uploadFiles(fileInput: HTMLInputElement): void {
    const fileList = fileInput.files;
    const selectedFiles = fileList ? Array.from(fileList) : [];
    this.sourceFileService.uploadFiles(selectedFiles);

    // Clear file input to be able to select the same file again
    fileInput.value = '';
  }

  public deleteSourceFile(sourceFile: SourceFileViewModel): void {
    this.sourceFileService.deleteSourceFile(sourceFile);
  }

  public deleteAlignment(model: AlignmentViewModel): void {
    this.alignmentService.deleteAlignment(model);
  }

  public editTrimBimSettings(model: AlignmentViewModel): void {
    this.overlayService
      .showOverlay(AlignmentTrimbimSettingsComponent, model, OverlayPosition.Bottom)
      .afterClosed<boolean>()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public toggleAlignment(model: AlignmentViewModel): void {
    if (model.show) {
      this.store.dispatch([
        new HideAlignment(model.id),
        new ClearNavigationState(model.id), // Clear navigation to refresh when shown again
      ]);
    } else {
      this.store.dispatch(new ShowAlignment(model.id, true));
    }
  }

  public toggleActiveAlignment(model: AlignmentViewModel) {
    if (model !== this.selectedAlignment()) {
      this.store.dispatch(new SetActiveAlignment(model.id));
    } else {
      this.store.dispatch(new SetActiveAlignment(undefined));
    }
  }

  private async addTrimBim(alignmentId: string, fitToView: boolean) {
    this.store.dispatch(new UpdateAlignment(alignmentId, { state: AlignmentLoadState.Loading }));

    try {
      const blob = await firstValueFrom(this.alignmentService.exportTrimbim(alignmentId));

      await this.workspace.api.viewer.addTrimbimModel({
        id: alignmentId,
        trbBlob: blob,
        fitToView: fitToView,
      });

      this.store.dispatch(new UpdateAlignment(alignmentId, { state: AlignmentLoadState.Ready }));
    } catch (err) {
      console.error(`Error adding TrimBim model to viewer for '${alignmentId}'`, err);

      this.store.dispatch(
        new UpdateAlignment(alignmentId, { state: AlignmentLoadState.NotLoaded }),
      );
    }
  }

  private async removeTrimBim(alignmentId: string) {
    try {
      await this.workspace.api.viewer.removeTrimbimModel(alignmentId);
    } catch (err) {
      console.error(`Error removing TrimBim model from viewer for '${alignmentId}'`, err);
    }

    this.store.dispatch(new UpdateAlignment(alignmentId, { state: AlignmentLoadState.NotLoaded }));
  }

  private getViewAction$(viewAction: ViewAction): Observable<unknown> {
    switch (viewAction.action) {
      case 'created':
        return this.viewService.createView(viewAction.view);
      case 'updated':
        return this.viewService.updateView(viewAction.view);
      case 'removed':
        return this.viewService.removeView(viewAction.view);
      case 'set': {
        return this.viewService.setView(viewAction.view);
      }
    }
  }
}
