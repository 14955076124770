import { SourceFileViewModel } from './source-file.view-models';

export class SetSourceFilesIsLoading {
  static readonly type = '[SourceFiles] SetSourceFilesIsLoading';
  constructor(public readonly isLoading: boolean) {}
}

export class SetSourceFilesLoadError {
  static readonly type = '[SourceFiles] SetSourceFilesLoadError';
  constructor(public readonly error: string | undefined) {}
}

export class SetSourceFiles {
  static readonly type = '[SourceFiles] SetSourceFiles';
  constructor(public readonly sourceFiles: SourceFileViewModel[]) {}
}

export class AddSourceFile {
  static readonly type = '[SourceFiles] AddSourceFile';
  constructor(public readonly sourceFile: SourceFileViewModel) {}
}

export class UpdateSourceFile {
  static readonly type = '[SourceFiles] UpdateSourceFile';
  constructor(
    public readonly sourceFileId: string,
    public readonly update: Partial<SourceFileViewModel>,
  ) {}
}

export class RemoveSourceFile {
  static readonly type = '[SourceFiles] RemoveSourceFile';
  constructor(public readonly sourceFileId: string) {}
}
