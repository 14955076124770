import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, catchError, filter, finalize, map, switchMap } from 'rxjs';
import { AlignmentApiService, AlignmentExportApiService } from '../../api';
import { DialogService } from '../../shared';
import { AppState } from '../app';
import {
  RemoveAlignment,
  SetActiveAlignment,
  SetAlignments,
  SetAlignmentsIsLoading,
  SetAlignmentsLoadError,
  UpdateAlignment,
} from './alignment.actions';
import { AlignmentViewModel, mapAlignment } from './alignment.view-models';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AlignmentService {
  constructor(
    private store: Store,
    private alignmentApiService: AlignmentApiService,
    private dialogService: DialogService,
    private alignmentExportApiService: AlignmentExportApiService,
  ) {}

  public loadAligments(): void {
    this.store
      .dispatch([
        new SetAlignmentsIsLoading(true),
        new SetAlignmentsLoadError(undefined),
        new SetAlignments([]),
        new SetActiveAlignment(undefined),
      ])
      .pipe(
        switchMap(() => this.getAlignments()),
        untilDestroyed(this),
        finalize(() => this.store.dispatch(new SetAlignmentsIsLoading(false))),
      )
      .subscribe({
        next: (alignments) => {
          this.store.dispatch(new SetAlignments(alignments));
        },
        error: (err) => {
          this.store.dispatch(new SetAlignmentsLoadError(err));
        },
      });
  }

  private getAlignments(): Observable<AlignmentViewModel[]> {
    return this.alignmentApiService.getAlignments().pipe(map((result) => result.map(mapAlignment)));
  }

  public deleteAlignment(alignment: AlignmentViewModel): void {
    this.store
      .dispatch(new UpdateAlignment(alignment.id, { deleting: true }))
      .pipe(
        switchMap(() =>
          this.dialogService.showConfirmation(
            `Are you sure you want to delete alignment [${alignment.name}]?`,
          ),
        ),
        untilDestroyed(this),
        filter((confirmed) => confirmed === true),
        switchMap(() => this.alignmentApiService.deleteAlignment(alignment.id)),
        switchMap(() => this.store.dispatch(new RemoveAlignment(alignment.id))),
        finalize(() => this.store.dispatch(new UpdateAlignment(alignment.id, { deleting: false }))),
      )
      .subscribe({
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }

  public exportTrimbim(alignmentId: string): Observable<Blob> {
    return this.store.select(AppState.uomPreference).pipe(
      switchMap((uomPreference) =>
        // Export using user's preferred unit
        this.alignmentExportApiService.exportTrimBim(alignmentId, uomPreference),
      ),
      catchError((err) => {
        // Retry using default unit
        console.error(`Error exporting alignment: ${err}, retrying with default unit`);
        return this.alignmentExportApiService.exportTrimBim(alignmentId);
      }),
    );
  }
}
