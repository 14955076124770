import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from './dialog.models';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public showConfirmation(message: string): Observable<boolean>;
  public showConfirmation(confirmData: ConfirmDialogData): Observable<boolean>;
  public showConfirmation(arg: string | ConfirmDialogData): Observable<boolean> {
    const dialogData = arg instanceof ConfirmDialogData ? arg : new ConfirmDialogData(arg);
    const dialogConfig: MatDialogConfig<ConfirmDialogData> = {
      data: dialogData,
      maxWidth: '95vw',
    };
    const dialogRef = this.dialog.open<ConfirmDialogComponent, unknown, boolean>(
      ConfirmDialogComponent,
      dialogConfig,
    );
    return dialogRef.afterClosed().pipe(map((result) => result ?? false));
  }
}
