import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { TrimBimExportArgs } from '../../api';
import { CacheDisplaySetting } from './display-setting.actions';

export interface DisplaySettingStateModel {
  cache: {
    [key: string]: TrimBimExportArgs;
  };
}

const defaultState: DisplaySettingStateModel = {
  cache: {},
};

@State<DisplaySettingStateModel>({
  name: 'displaySettingState',
  defaults: defaultState,
})
@Injectable()
export class DisplaySettingState {
  static getSettings(alignmentId: string): (state: DisplaySettingStateModel) => TrimBimExportArgs {
    return createSelector([DisplaySettingState], (state: DisplaySettingStateModel) => {
      const settings = state.cache[alignmentId];
      return settings;
    });
  }

  @Action(CacheDisplaySetting) cacheDisplaySetting(
    ctx: StateContext<DisplaySettingStateModel>,
    action: CacheDisplaySetting,
  ) {
    ctx.setState(
      patch({
        cache: patch({ [action.alignmentId]: action.settings }),
      }),
    );
  }
}
