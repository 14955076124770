import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  isDefined,
  isNil,
} from '@trimble-gcs/modus';
import { filter, finalize, map, switchMap, take } from 'rxjs';
import { PointPickedEventArgument } from 'trimble-connect-workspace-api';
import { AlignmentCalculationsApiService, AlignmentMeasurementApiService } from '../../api';
import { UomPipe } from '../../pipes';
import { BusyIndicatorComponent, OVERLAY_DATA, OverlayReference } from '../../shared';
import { AppState, MeasurementViewModel, mapMeasurement } from '../../state';

enum MeasureState {
  None = 'None',
  Measuring = 'Select a position in the 3D viewer',
  Projecting = 'Projecting to alignment',
  Error = 'Error',
}

@UntilDestroy()
@Component({
  selector: 'nzc-measurement-detail',
  standalone: true,
  imports: [
    ModusFormFieldModule,
    ModusButtonModule,
    ModusIconModule,
    ModusInputModule,
    ReactiveFormsModule,
    BusyIndicatorComponent,
    UomPipe,
  ],
  templateUrl: './measurement-detail.component.html',
  styleUrls: ['./measurement-detail.component.scss'],
})
export class MeasurementDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentClasses = 'flex flex-col grow';

  private readonly workspace = this.store.selectSnapshot(AppState.workspace);

  public measureState = MeasureState.None;
  public saving = false;
  public projectionError?: string;

  public readonly formGroup = new FormGroup({
    name: new FormControl<string | null>('', Validators.required),
  });

  constructor(
    private store: Store,
    private calculationsService: AlignmentCalculationsApiService,
    private measurementService: AlignmentMeasurementApiService,
    private overlayRef: OverlayReference<MeasurementViewModel>,
    @Inject(OVERLAY_DATA) public measurement: MeasurementViewModel,
  ) {}

  public get isNew(): boolean {
    return isNil(this.measurement.id);
  }

  public get isBusy(): boolean {
    return (
      this.measureState === MeasureState.Measuring || this.measureState === MeasureState.Projecting
    );
  }

  public async ngOnInit(): Promise<void> {
    if (this.isNew) {
      await this.startMeasurement();
    } else {
      this.formGroup.reset(this.measurement);
    }
  }

  public async ngOnDestroy(): Promise<void> {
    await this.stopMeasurement();
  }

  private async startMeasurement(): Promise<void> {
    this.measureState = MeasureState.Measuring;
    this.projectionError = undefined;

    // Activate single-point measurement
    if (!(await this.workspace.activateTool('pointMarkup'))) {
      this.measureState = MeasureState.None;
      return;
    }

    // Get current single point markups to be able to remove added markup
    const pointMarkups = await this.workspace.getSinglePointMarkups();

    // Listen for first picked event, then project to alignment
    this.workspace.event$
      .pipe(
        untilDestroyed(this),
        filter((event) => event.id === 'viewer.onPicked'),
        map((event) => event.arg as PointPickedEventArgument),
        filter((pointSelection) => isDefined(pointSelection.data.position)),
        take(1),
        switchMap((pointSelection) => {
          this.measureState = MeasureState.Projecting;
          return this.calculationsService.projectToStation(
            this.measurement.alignmentId,
            pointSelection.data.position,
          );
        }),
        finalize(async () => {
          await this.workspace.removeAddedSinglePointMarkups(pointMarkups);
          this.stopMeasurement();
        }),
      )
      .subscribe({
        next: async (projection) => {
          this.measurement.projection = projection;
          await this.workspace.createMeasurementMarkup(this.measurement);
        },
        error: (err) => {
          this.projectionError = err;
        },
      });
  }

  private async stopMeasurement(): Promise<void> {
    this.measureState = MeasureState.None;

    // Reset tools
    await this.workspace.activateTool('reset');
  }

  public async cancelChanges(): Promise<void> {
    // Remove unsaved markup
    if (this.isNew) {
      await this.workspace.removeMeasurementMarkup(this.measurement);
    }

    this.overlayRef.close();
  }

  public saveChanges(): void {
    this.measurement.name = this.formGroup.controls.name.value ?? '';

    const save$ = this.isNew
      ? this.measurementService.createMeasurement(this.measurement.alignmentId, this.measurement)
      : this.measurementService.updateMeasurement(this.measurement.alignmentId, this.measurement);

    this.saving = true;
    save$.pipe(finalize(() => (this.saving = false))).subscribe({
      next: async (savedMeasurement) => {
        await this.workspace.removeMeasurementMarkup(this.measurement);

        this.overlayRef.close(mapMeasurement(savedMeasurement));
      },
      error: (err) => {
        // TODO: Show errors
        // this.toastr.error(err);
        console.error('saveChanges', err);
      },
    });
  }
}
