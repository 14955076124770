import { isDefined } from '@trimble-gcs/common';
import { arrayIntersect, round } from '../common';
import { ResolvedUnit, RoundedScaler, Unit, UnitType } from './uom.models';

export class UomConverter {
  constructor(private unitTypes: UnitType[]) {
    if (!unitTypes) {
      throw new Error('Unit types must be specifed');
    }
  }

  public static convertToNative(unit: Unit, value: number | undefined): number | undefined {
    if (value == null) {
      return undefined;
    }

    return (value + unit.offsetToNative) * unit.factorToNative;
  }

  public static convertFromNative(unit: Unit, value: number | undefined): number | undefined {
    if (value == null) {
      return undefined;
    }

    return value / unit.factorToNative - unit.offsetToNative;
  }

  public resolveLenient(unit: string, unitType?: string): ResolvedUnit[] {
    // Create local copy
    let unitTypes = this.unitTypes.slice(0);

    // Filter by unit type
    if (unitType) {
      unitTypes = unitTypes.filter((ut) => ut.id === unitType);
    }

    // Create alias predicate
    const unitSearch = unit ? unit.toLowerCase() : '';
    const matchAlias = (u: Unit) => u.aliases.some((alias) => alias === unitSearch);

    // Filter unit types by units with matching alias
    unitTypes = unitTypes.filter((ut) => ut.units?.some(matchAlias));

    // Create resolved units
    const resolvedUnits = unitTypes.map((ut) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const unit = ut.units.find(matchAlias)!;
      const resolvedUnit: ResolvedUnit = {
        unit: unit,
        unitType: ut,
        displayName: `${ut.id} [${unit.name} (${unit.symbol})]`,
      };
      return resolvedUnit;
    });
    return resolvedUnits;
  }

  public resolveStrict(unit: string, unitType?: string): ResolvedUnit {
    // Resolve possible units
    const resolvedUnits = this.resolveLenient(unit, unitType);

    // Ensure at least one unit resolved
    if (!resolvedUnits.length) {
      throw new Error(`Could not resolve unit for: ${unit}`);
    }

    // Ensure only one unit resolved
    if (resolvedUnits.length > 1) {
      const units = resolvedUnits.map((u) => u.displayName).join(', ');
      throw new Error(`Multiple matches found: ${units}`);
    }

    // Return first
    return resolvedUnits[0];
  }

  public convert(
    value: number,
    fromUnit: string,
    toUnit: string,
    unitType?: string,
    decimals?: number,
  ): RoundedScaler {
    // Try to find common UnitType.
    // This helps to elliminate the odds of finding a duplicate match (e.g. g for acceleration, grams and gons)
    const inputUnits = this.resolveLenient(fromUnit, unitType);
    const outputUnits = this.resolveLenient(toUnit, unitType);

    // Ensure we have resolved both intput and output units
    if (!inputUnits.length) {
      throw new Error(`Could not resolve input unit for: ${fromUnit}`);
    }
    if (!outputUnits.length) {
      throw new Error(`Could not resolve output unit for: ${toUnit}`);
    }

    // Find the common/matching unit types
    const commonUnitTypes = arrayIntersect(inputUnits, outputUnits, (ru) => ru.unitType.id).map(
      (ru) => ru.unitType.id,
    );

    // Ensure we have a common unit type to convert from/to
    let inputUnitType = unitType;
    let outputUnitType = unitType;
    if (!commonUnitTypes.length) {
      throw new Error(
        `Cannot convert from ${inputUnits[0].displayName} to ${outputUnits[0].displayName}`,
      );
    }
    if (commonUnitTypes.length === 1) {
      inputUnitType = outputUnitType = commonUnitTypes[0];
    }

    // Convert input to native
    const resolvedInputUnit = this.resolveStrict(fromUnit, inputUnitType);
    const nativeValue = UomConverter.convertToNative(resolvedInputUnit.unit, value);

    // Convert to output from native
    const resolvedOutputUnit = this.resolveStrict(toUnit, outputUnitType);
    const outputValue = UomConverter.convertFromNative(resolvedOutputUnit.unit, nativeValue);
    const roundedValue =
      isDefined(outputValue) && decimals ? round(outputValue, decimals) : outputValue;

    // Return result
    const convertedValue: RoundedScaler = {
      unit: resolvedOutputUnit.unit.name,
      unitType: resolvedOutputUnit.unitType.id,
      value: outputValue,
      roundedValue: roundedValue,
      symbol: resolvedOutputUnit.unit.symbol,
    };
    return convertedValue;
  }
}
