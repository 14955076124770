import { IFormattingSettings, ProjectSettings } from 'trimble-connect-workspace-api';
import { round } from '../common';
import { UomConverter } from './uom-converter';
import { LinearUnit, UnitTypeId } from './uom.models';

interface IUnit {
  unit: string;
  decimals: number;
}

export class UomFormatter {
  constructor(
    private uomConverter: UomConverter,
    private projectSettings: ProjectSettings,
  ) {}

  public format(value: number, asMeasurement: boolean): string {
    try {
      const formatting = this.projectSettings.formatting as Required<IFormattingSettings>;
      const format = this.getFormat(formatting, asMeasurement);
      const convertedValue = this.uomConverter.convert(
        value,
        LinearUnit.Meter,
        format.unit,
        UnitTypeId.Linear,
        format.decimals,
      );
      const roundedValue = convertedValue.roundedValue?.toFixed(format.decimals);
      const formattedValue = `${roundedValue} ${format.unit}`;
      return formattedValue;
    } catch (e) {
      console.error(e);

      // Default to 'meter' rounded to 2 decimals
      return `${round(value, 2)} ${LinearUnit.Meter}`;
    }
  }

  private getFormat(formatting: Required<IFormattingSettings>, asMeasurement: boolean): IUnit {
    if (asMeasurement) {
      return {
        unit: formatting.lengthMeasurementUnit,
        decimals: formatting.lengthMeasurementDecimals,
      };
    } else {
      return {
        unit: formatting.lengthUnit,
        decimals: formatting.lengthDecimals,
      };
    }
  }
}
