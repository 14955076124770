<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>{{ data.message }}</div>
<div mat-dialog-actions>
  @if (data.hasCancelButton) {
    <button modus-stroked-button color="secondary" (click)="cancel()">
      {{ data.cancelText }}
    </button>
  }
  <button modus-flat-button color="primary" button (click)="confirm()">
    {{ data.okText }}
  </button>
</div>
