import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { filter, map } from 'rxjs';
import {
  AccessTokenEventArgument,
  EventId,
  EventToArgMap,
  ProjectSettingsChangedEventArgument,
  WorkspaceAPI,
  connect,
} from 'trimble-connect-workspace-api';
import { ClearAuth, SetConnectToken, SetProject, SetProjectSettings, SetWorkspace } from '../state';
import { ConnectWorkspace, WorkspaceEvent } from './connect.models';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private _workspace?: ConnectWorkspace;

  constructor(private store: Store) {}

  public async initialize(
    target?: Window | HTMLIFrameElement,
    timeout?: number | undefined,
  ): Promise<void> {
    if (isNil(this._workspace) && isDefined(target)) {
      await this.initWorkspace(target, timeout);
    }
  }

  private async initWorkspace(target: Window | HTMLIFrameElement, timeout?: number | undefined) {
    const api = await this.createWorkspace(target, timeout);

    if (isWorkspaceAPI(api)) {
      this._workspace = new ConnectWorkspace(api, this.store);
      this.store.dispatch(new SetWorkspace(this._workspace));

      await this.initProject(this._workspace);
      await this.initProjectSettings(this._workspace);
      await this.initAccessToken(this._workspace);
    }
  }

  private async createWorkspace(
    target: Window | HTMLIFrameElement,
    timeout?: number | undefined,
  ): Promise<WorkspaceAPI> {
    const onEvent = this.onWorkspaceEvent.bind(this);
    const workspaceApi = await connect(target, onEvent, timeout);
    return workspaceApi;
  }

  private async initProject(workspace: ConnectWorkspace): Promise<void> {
    // Get current project and update state
    const project = await workspace.api.project.getProject();
    this.store.dispatch(new SetProject(project));
  }

  private async initProjectSettings(workspace: ConnectWorkspace): Promise<void> {
    // Listen to project setting changes
    workspace.event$
      .pipe(
        filter((event) => event.id === 'project.onSettingsChanged'),
        map((event) => event.arg as ProjectSettingsChangedEventArgument),
        filter((settingsEvent) => settingsEvent.data.changes.includes('formatting')),
      )
      .subscribe({
        next: async () => {
          const projectSettings = await workspace.api.project.getSettings();
          this.store.dispatch(new SetProjectSettings(projectSettings));
        },
      });

    // Get current project settings and update state
    const projectSettings = await workspace.api.project.getSettings();
    this.store.dispatch(new SetProjectSettings(projectSettings));
  }

  private async initAccessToken(workspace: ConnectWorkspace): Promise<void> {
    // Listen to accessToken change (renewal or after allowed access prompt)
    workspace.event$
      .pipe(
        filter((event) => event.id === 'extension.accessToken'),
        map((event) => event.arg as AccessTokenEventArgument),
      )
      .subscribe({
        next: (accessTokenEvent) => {
          const connectToken = accessTokenEvent.data;
          this.store.dispatch(new SetConnectToken(connectToken));
        },
      });

    // Request connect access token
    const token = await workspace.api.extension.requestPermission('accesstoken');
    if (token.toLowerCase() === 'pending') {
      this.store.dispatch(new ClearAuth());
    } else {
      this.store.dispatch(new SetConnectToken(token));
    }
  }

  private onWorkspaceEvent<TEvent extends EventId, TData extends EventToArgMap[TEvent]>(
    eventId: TEvent,
    eventData: TData,
  ) {
    const event: WorkspaceEvent<TEvent, TData> = { id: eventId, arg: eventData };
    this._workspace?.['_event$'].next(event);

    // console.log(`ConnectEvent [${eventId}]`, eventData);
  }
}

export function isWorkspaceAPI(arg: unknown): arg is WorkspaceAPI {
  return (arg as WorkspaceAPI).extension !== undefined;
}
