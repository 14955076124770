export enum AxisType {
  Category = 'Category',
  Numeric = 'Numeric',
  DateTime = 'DateTime',
}

export interface IPlotAxis {
  name: string;
  axisType: AxisType;
}

export interface ICategoryAxis extends IPlotAxis {
  categories: string[];
}

export interface INumericAxis extends IPlotAxis, IPlotComponentInfo {
  start: number;
}

export interface IDateTimeAxis extends IPlotAxis {
  startDateTime: Date;
  incrementMs: number;
}

export interface IPlotComponentInfo {
  name: string;
}

export interface ICompactPlotSet<TAxis extends IPlotAxis> {
  xAxis: TAxis;
  components: IPlotComponentInfo[];
  values: number[][];
}
