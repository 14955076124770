<!-- Header -->
<div class="flex flex-col border-b border-gray-1">
  <div class="flex flex-row items-center pl-3 pt-2 pr-2 pb-2 text-xl font-medium">
    Alignments
    <div class="flex flex-1 justify-end">
      <button modus-icon-button modus-tooltip="Refresh" class="text-gray-8" (click)="refresh()">
        <md-icon>refresh</md-icon>
      </button>
      <button
        modus-icon-button
        modus-tooltip="Create alignment from file"
        tooltipClass="arrow-right"
        class="text-gray-8"
        (click)="fileInput.click()"
      >
        <md-icon>upload</md-icon>
      </button>
      <input
        hidden
        #fileInput
        type="file"
        accept=".track"
        multiple
        (change)="uploadFiles(fileInput)"
      />
    </div>
  </div>

  <!-- Filter -->
  <!--
  <div class="pl-3 pr-[1.2rem] pb-2">
    <mat-select
      class="modus-dropdown"
      panelClass="modus-dropdown-panel"
      [formControl]="filterOptionControl"
      disableRipple
    >
      <mat-option [value]="AlignmentListFilterOption.ShowAll">All Alignments</mat-option>
      <mat-option [value]="AlignmentListFilterOption.ShowSelected">Selected</mat-option>
    </mat-select>
  </div>
  -->
</div>

<!-- Loader -->
@if (loading() || (alignments().length === 0 && sourceFiles().length === 0)) {
  <div class="flex flex-col grow items-center justify-center p-3">
    <nzc-busy-indicator [busy]="loading()">
      <!-- No items -->
      <div class="flex flex-col items-center justify-center">
        <img src="assets/images/no-alignments.svg" class="mb-3" />
        @if (loadingError()) {
          <div class="text-white bg-red-light rounded-sm p-2 text-sm break-anywhere">
            <div class="font-bold">Error loading data!</div>
            <div class="mt-2">{{ loadingError() }}</div>
          </div>
        } @else {
          <div class="text-center">
            <div class="text-2xl font-semibold">No Alignments</div>
            <div class="mt-2">
              Looks like you haven't added any alignments yet. Start by uploading new items.
            </div>
          </div>
        }
      </div>
    </nzc-busy-indicator>
  </div>
} @else {
  <!-- Lists -->
  <div class="flex flex-col grow basis-1 overflow-y-auto" cdkScrollable>
    <!-- Source Files (not ingested) -->
    @if (sourceFiles().length > 0) {
      @for (sourceFile of sourceFiles(); track sourceFile.id) {
        <div class="flex flex-row min-h-[32px] text-sm hover:bg-gray-0 px-2">
          <!-- Icon -->
          <div class="flex justify-center items-center min-w-[32px]">
            @switch (sourceFile.state) {
              <!-- Uploading - Show determinate progress -->
              @case (SourceFileState.Uploading) {
                <mat-progress-spinner
                  mode="determinate"
                  class="spinner-inline"
                  [value]="sourceFile.uploadProgress?.percentage"
                >
                </mat-progress-spinner>
              }
              <!-- Ingesting - Show indeterminate progress -->
              @case (SourceFileState.Pending) {
                <mat-progress-spinner
                  mode="indeterminate"
                  class="spinner-inline"
                ></mat-progress-spinner>
              }
              <!-- Failed - Show error icon with error message tooltip-->
              @case (SourceFileState.Failed) {
                <md-icon
                  [modus-tooltip]="
                    sourceFile.errors.length > 0
                      ? sourceFile.errors[0].message
                      : 'Error ingesting file'
                  "
                  class="error"
                  tooltipClass="error arrow-left"
                >
                  alert
                </md-icon>
              }
              <!-- Ingested - Show success icon  -->
              @case (SourceFileState.Ingested) {
                <md-icon class="success">check_circle</md-icon>
              }
            }
          </div>

          <!-- Text -->
          <div
            modus-tooltip="{{ sourceFile.filename }}"
            class="flex items-center w-full overflow-hidden p-1"
          >
            <div
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
              [ngClass]="{ 'text-red': sourceFile.state === SourceFileState.Failed }"
            >
              {{ sourceFile.filename }}
            </div>
          </div>

          <!-- Options -->
          <div class="flex flex-row items-center">
            @if (sourceFile.state === SourceFileState.Failed) {
              <button
                modus-icon-button
                modus-tooltip="Delete"
                tooltipClass="arrow-right"
                (click)="deleteSourceFile(sourceFile)"
                [disabled]="sourceFile.deleting"
              >
                <md-icon>delete</md-icon>
              </button>
            }
          </div>
        </div>
      }

      <!-- Divider -->
      <div class="border-b border-gray-1"></div>
    }

    <!-- Alignments -->
    @for (alignment of alignments(); track alignment.id) {
      <div class="flex flex-row min-h-[32px] text-sm hover:bg-gray-0 px-2">
        <!-- Icon -->
        <nzc-busy-indicator
          [busy]="alignment.state === AlignmentLoadState.Loading"
          [showLabel]="false"
        >
          @switch (alignment.state) {
            @case (AlignmentLoadState.NotLoaded) {
              <button
                modus-icon-button
                modus-tooltip="Load alignment"
                tooltipClass="arrow-left"
                (click)="toggleAlignment(alignment)"
                [disabled]="alignment.deleting"
              >
                <i class="tc-icon-eye-visibility-off-download opacity-60"></i>
              </button>
            }
            @case (AlignmentLoadState.Ready) {
              <button
                modus-icon-button
                modus-tooltip="Unload alignment"
                tooltipClass="arrow-left"
                (click)="toggleAlignment(alignment)"
                [disabled]="alignment.deleting"
              >
                <i class="tc-icon-eye-visibility"></i>
              </button>
            }
          }
        </nzc-busy-indicator>

        <!-- Text -->
        <div
          modus-tooltip="{{ alignment.name }}"
          class="flex items-center w-full overflow-hidden p-1"
        >
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
            [ngClass]="{ 'text-red': alignment.state === AlignmentLoadState.Failed }"
          >
            {{ alignment.name }}
            @if (alignment.state === AlignmentLoadState.Failed) {
              <div class="text-small italic text-gray-6">{{ alignment.state }}</div>
            }
          </div>
        </div>

        <!-- Options -->
        <div class="flex flex-row items-center">
          <!-- Active Toggle -->
          @if (alignment.state === AlignmentLoadState.Ready) {
            <button
              modus-icon-button
              (click)="toggleActiveAlignment(alignment)"
              [disabled]="alignment.deleting"
              [modus-tooltip]="alignment.active ? 'Deactivate' : 'Activate'"
            >
              @if (!alignment.active) {
                <md-icon class="opacity-60">lock_open</md-icon>
              } @else {
                <md-icon>lock</md-icon>
              }
            </button>
          }

          <!-- Menu -->
          <button modus-icon-button [modusMenuTriggerFor]="alignmentMenu">
            <md-icon>more_vertical</md-icon>
          </button>
          <md-menu #alignmentMenu>
            <md-menu-item
              (itemClick)="editTrimBimSettings(alignment)"
              [disabled]="alignment.deleting"
              >Display Settings</md-menu-item
            >
            <md-menu-item (itemClick)="deleteAlignment(alignment)" [disabled]="alignment.deleting"
              >Delete</md-menu-item
            >
          </md-menu>
        </div>
      </div>
    }
  </div>

  <!-- Select Alignment Properties -->
  @if (selectedAlignment()) {
    <mat-tab-group
      color="primary"
      class="mds-small overflow-hidden basis-auto min-h-[50%] shadow-top-separator"
    >
      <mat-tab label="View">
        <nzc-alignment-navigation [alignment]="selectedAlignment()"></nzc-alignment-navigation>
      </mat-tab>

      <mat-tab label="Measure" bodyClass="pad-none">
        <nzc-measurement-list [alignment]="selectedAlignment()"></nzc-measurement-list>
      </mat-tab>
    </mat-tab-group>
  }
}
