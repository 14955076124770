import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  computed,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSelectModule,
} from '@trimble-gcs/modus';
import { Subject, debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs';
import { Color, TrimBimExportArgs } from '../../api';
import { UomPipe } from '../../pipes';
import {
  BusyIndicatorComponent,
  OVERLAY_DATA,
  OverlayReference,
  enumPairs,
  splitPascalCase,
} from '../../shared';
import { AlignmentViewModel, DisplaySettingService, ReloadAlignment } from '../../state';

@UntilDestroy()
@Component({
  selector: 'nzc-alignment-trimbim-settings',
  standalone: true,
  imports: [
    MatSliderModule,
    ModusFormFieldModule,
    ModusButtonModule,
    ModusIconModule,
    ModusInputModule,
    ModusSelectModule,
    ReactiveFormsModule,
    BusyIndicatorComponent,
    UomPipe,
  ],
  templateUrl: './alignment-trimbim-settings.component.html',
  styleUrls: ['./alignment-trimbim-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlignmentTrimbimSettingsComponent implements OnInit {
  @HostBinding('class') componentClasses = 'flex flex-col grow';

  private readonly saveSettingsSubject = new Subject<TrimBimExportArgs>();

  public readonly saving = signal(false);
  public readonly loading = signal(false);
  public readonly busy = computed(() => this.loading() || this.saving());
  public readonly settings = signal<TrimBimExportArgs | undefined>(undefined);
  public readonly colors = enumPairs<string>(Color).map((p) => ({
    ...p,
    key: splitPascalCase(p.key),
  }));

  public readonly formGroup = new FormGroup({
    beginStation: new FormControl<number | null>(null),
    endStation: new FormControl<number | null>(null),
    colorName: new FormControl<string | null>(null),
    stationLabelSize: new FormControl<number | null>(null),
    stationLabelVerticalOffset: new FormControl<number | null>(null),
    stationLabelSpacing: new FormControl<number | null>(null),
  });

  constructor(
    private displaySettingService: DisplaySettingService,
    private overlayRef: OverlayReference<boolean>,
    @Inject(OVERLAY_DATA) public alignment: AlignmentViewModel,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.loadSettings();

    // We use a standalone subject for after-value-change events (e.g. formGroup raises events while dragging)
    this.saveSettingsSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)),
      )
      .subscribe((settings) => {
        this.saveSettings(settings);
      });
  }

  private loadSettings(): void {
    this.settings.set(undefined);
    this.loading.set(true);
    this.displaySettingService
      .getDisplaySettings(this.alignment.id)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: (settings) => {
          this.settings.set(settings);
          this.formGroup.reset(settings);
        },
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }

  public close(): void {
    this.overlayRef.close(false);
  }

  public afterValueChanged(): void {
    this.saveSettingsSubject.next(this.formGroup.value as TrimBimExportArgs);
  }

  private saveSettings(settings: TrimBimExportArgs): void {
    this.saving.set(true);
    this.displaySettingService
      .updateDisplaySettings(this.alignment.id, settings)
      .pipe(
        takeUntil(this.saveSettingsSubject.asObservable()),
        finalize(() => this.saving.set(false)),
      )
      .subscribe({
        next: () => {
          // Reload/export alignment if shown
          if (this.alignment.show) {
            this.store.dispatch(new ReloadAlignment(this.alignment.id));
          }
        },
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }
}
