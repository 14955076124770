import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  BehaviorSubject,
  debounce,
  filter,
  of,
  share,
  skip,
  Subject,
  takeUntil,
  timer,
} from 'rxjs';
import {
  ColorRGBA,
  EventId,
  EventToArgMap,
  MarkupPick,
  MeasurementMarkup,
  PointIcon,
  PointMarkup,
  TextMarkup,
  WorkspaceAPI,
} from 'trimble-connect-workspace-api';
import { PointCoordinate } from '../api';
import { arrayExcept, UomFormatter } from '../shared';
import { AppState, MeasurementViewModel } from '../state';

export type WorkspaceEvent<TEvent extends EventId, TData extends EventToArgMap[TEvent] = never> = {
  id: TEvent;
  arg: TData;
};

export type ViewerTool =
  | 'pointMarkup'
  | 'measurement'
  | 'angleMeasurement'
  | 'slopeMeasurement'
  | 'polylineMeasurement'
  | 'reset';

export class ConnectWorkspace {
  private _event$ = new Subject<WorkspaceEvent<EventId, EventToArgMap[EventId]>>();
  private _lookAtMarkerSubject = new BehaviorSubject<PointIcon | undefined>(undefined);

  public event$ = this._event$.pipe(share());

  public command$ = this._event$.pipe(
    filter((event) => event.id === 'extension.command'),
    share(),
  );

  constructor(
    public api: WorkspaceAPI,
    private store: Store,
  ) {
    // Conditional debounced update of look-at marker as fast subquent updates causes errors
    let updatingMarker = false;
    this._lookAtMarkerSubject
      .pipe(debounce(() => (updatingMarker ? timer(50) : of(undefined))))
      .subscribe(async (marker) => {
        if (isDefined(marker)) {
          try {
            updatingMarker = true;
            await this.api.viewer.addIcon(marker);
          } catch (err) {
            // console.error('updateLookAtMarker', err);

            // Retry with last marker and only if no new update received
            timer(50)
              .pipe(takeUntil(this._lookAtMarkerSubject.pipe(skip(1)))) // Skip(1) as this is a behaviour subject
              .subscribe(() => {
                this._lookAtMarkerSubject.next(marker);
              });
          } finally {
            updatingMarker = false;
          }
        }
      });
  }

  public async activateTool(tool: ViewerTool): Promise<boolean> {
    try {
      await this.api.viewer.activateTool(tool);
      return true;
    } catch (err) {
      console.error('activateTool', err);
    }

    return false;
  }

  private convertToMarkupUnit(value: number): number {
    return value * 1000; // Convert meter positions to mm for markups
  }

  private toMarkupPosition(coordinate: PointCoordinate): MarkupPick {
    const markupPosition: MarkupPick = {
      positionX: this.convertToMarkupUnit(coordinate.x),
      positionY: this.convertToMarkupUnit(coordinate.y),
      positionZ: this.convertToMarkupUnit(coordinate.z),
    };
    return markupPosition;
  }

  public async createMeasurementMarkup(measurement: MeasurementViewModel): Promise<void> {
    // Ensure required values are set
    const projection = measurement.projection;
    if (
      !projection.pointCoordinate ||
      !projection.alignmentCoordinate ||
      !projection.groundCoordinate ||
      !projection.alignmentStation
    ) {
      return;
    }

    try {
      const markupColor: ColorRGBA = { r: 200, g: 25, b: 34, a: 255 }; // Red

      // Create measurement markups
      const measurementMarkups: MeasurementMarkup[] = [
        // Picked position to Alignment position
        {
          color: markupColor,
          start: this.toMarkupPosition(projection.pointCoordinate),
          mainLineStart: this.toMarkupPosition(projection.pointCoordinate),
          end: this.toMarkupPosition(projection.alignmentCoordinate),
          mainLineEnd: this.toMarkupPosition(projection.alignmentCoordinate),
        },
        // Alignment position to Ground position
        {
          color: markupColor,
          start: this.toMarkupPosition(projection.alignmentCoordinate),
          mainLineStart: this.toMarkupPosition(projection.alignmentCoordinate),
          end: this.toMarkupPosition(projection.groundCoordinate),
          mainLineEnd: this.toMarkupPosition(projection.groundCoordinate),
        },
        // Ground position to Picked position
        {
          color: markupColor,
          start: this.toMarkupPosition(projection.groundCoordinate),
          mainLineStart: this.toMarkupPosition(projection.groundCoordinate),
          end: this.toMarkupPosition(projection.pointCoordinate),
          mainLineEnd: this.toMarkupPosition(projection.pointCoordinate),
        },
      ];

      const addedMeasureMarkups = await this.api.markup.addMeasurementMarkups(measurementMarkups);
      measurement.markupIds = addedMeasureMarkups
        .filter((m) => isDefined(m.id))
        .map((m) => m.id as number);

      // Create text markups
      const textMarkups: TextMarkup[] = [
        {
          // Create chainage label
          color: markupColor,
          start: this.toMarkupPosition(projection.alignmentCoordinate),
          end: this.toMarkupPosition(projection.alignmentCoordinate),
          text: this.formatValue(projection.alignmentStation.station),
        },
      ];

      const addedTextMarkups = await this.api.markup.addTextMarkup(textMarkups);
      measurement.markupIds.push(
        ...addedTextMarkups.filter((m) => isDefined(m.id)).map((m) => m.id as number),
      );
    } catch (err) {
      console.error('addAlignmentMeasurement', err);
    }
  }

  private formatValue(value: number, asMeasurement = false): string {
    const uomConverter = this.store.selectSnapshot(AppState.uomConverter);
    const projectSettings = this.store.selectSnapshot(AppState.projectSettings);
    const uomFormatter = new UomFormatter(uomConverter, projectSettings);
    return uomFormatter.format(value, asMeasurement);
  }

  public async removeMeasurementMarkup(measurement: MeasurementViewModel): Promise<void> {
    // Ensure required values are set
    if (!measurement.markupIds || measurement.markupIds.length === 0) {
      return undefined;
    }

    try {
      await this.api.markup.removeMarkups(measurement.markupIds);
      measurement.markupIds = undefined;
    } catch (err) {
      console.error('removeMeasurementMarkup', err);
    }
  }

  public async getSinglePointMarkups(): Promise<PointMarkup[]> {
    try {
      const markups = await this.api.markup.getSinglePointMarkups();
      return markups;
    } catch (err) {
      console.error('getSinglePointMarkups', err);
    }

    return [];
  }

  public async removeAddedSinglePointMarkups(previousMarkups: PointMarkup[]): Promise<void> {
    try {
      const currentMarkups = await this.getSinglePointMarkups();
      const addedMarkups = arrayExcept(currentMarkups, previousMarkups, (m) => m.id ?? m);

      const markupIds = addedMarkups.filter((m) => isDefined(m.id)).map((m) => m.id as number);
      await this.api.markup.removeMarkups(markupIds);
    } catch (err) {
      console.error('removeAddedSinglePointMarkups', err);
    }
  }

  private resolveAssetPath(relativePath: string): string {
    const url = new URL(window.location.href);
    return `${url.origin}/assets/${relativePath}`;
  }

  public setLookAtMarker(coordinate: PointCoordinate): void {
    let lookAtMarker = this._lookAtMarkerSubject.getValue();
    if (isNil(lookAtMarker)) {
      lookAtMarker = {
        id: crypto.getRandomValues(new Uint32Array(1))[0],
        position: coordinate,
        iconPath: this.resolveAssetPath('icons/look-at-marker.png'),
        size: 10,
      };
    } else {
      lookAtMarker.position = coordinate;
    }

    this._lookAtMarkerSubject.next(lookAtMarker);
  }

  public async removeLookAtMarker(): Promise<void> {
    const lookAtMarker = this._lookAtMarkerSubject.getValue();
    if (isDefined(lookAtMarker)) {
      await this.api.viewer.removeIcon(lookAtMarker);
    }
  }
}
