export enum AlignmentSourceFileState {
  Uploading = 'Uploading', // Not an actual API state
  Pending = 'Pending',
  Ingested = 'Ingested',
  Failed = 'Failed',
}

export interface AlignmentSourceFile {
  id: string;
  filename: string;
  state: AlignmentSourceFileState;
  errors: ValidationError[];
  alignments: AlignmentValidation[];
}

export interface ValidationError {
  code: string;
  message: string;
}

export interface AlignmentValidation {
  alignmentId: string;
  beginStation: number;
  endStation: number;
  validationPassed: boolean;
  validatedAt: Date;
  pointsEvaluated?: number;
  maximumOffsetError?: number;
  maximumHeightError?: number;
  maximumStationError?: number;
  error?: ValidationError;
}

export interface UploadProgress<T> {
  percentage: number;
  result?: T;
}
