import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { combineLatest } from 'rxjs';
import { ProjectSettings } from 'trimble-connect-workspace-api';
import { UomConverter, UomFormatter } from '../shared';
import { AppState } from '../state';

@UntilDestroy()
@Pipe({
  standalone: true,
  name: 'uom',
  pure: false, // Not pure, as we need to update on settings change
})
export class UomPipe implements PipeTransform {
  private readonly defaultValue = '--';

  private shouldUpdate = false;
  private lastValue: number | undefined;
  private lastFormattedValue = this.defaultValue;
  private uomConverter?: UomConverter;
  private projectSettings?: ProjectSettings;

  constructor(
    store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    // Mark for update on UomConverter or ProjectSettings changes
    combineLatest([store.select(AppState.uomConverter), store.select(AppState.projectSettings)])
      .pipe(untilDestroyed(this))
      .subscribe(([uomConverter, projectSettings]) => {
        this.shouldUpdate = true;
        this.uomConverter = uomConverter;
        this.projectSettings = projectSettings;
        this.changeDetectorRef.markForCheck();
      });
  }

  public transform(value: number | null | undefined, asMeasurement = false): string | undefined {
    // Reformat on subscribed changes or different value
    if (this.shouldUpdate || value !== this.lastValue) {
      this.shouldUpdate = false;
      this.lastValue = value ?? undefined;
      if (isDefined(this.uomConverter) && isDefined(this.projectSettings) && isDefined(value)) {
        const uomFormatter = new UomFormatter(this.uomConverter, this.projectSettings);
        this.lastFormattedValue = uomFormatter.format(value, asMeasurement);
      } else {
        this.lastFormattedValue = this.defaultValue;
      }

      return this.lastFormattedValue;
    }

    // Else return previously formatted value
    return this.lastFormattedValue;
  }
}
