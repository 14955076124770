import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { Observable, map } from 'rxjs';
import { catchHttpError } from '../../shared';
import { AppState } from '../../state';
import { AlignmentSourceFile, AlignmentSourceFileState, UploadProgress } from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class AlignmentSourceFileApiService {
  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {}

  private getUrl(sourceFileId?: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();

    relativeUrl = isNil(sourceFileId) ? relativeUrl : `/${sourceFileId}${relativeUrl}`;

    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const url = `${baseUrl}/tenants/${tenantId}/sourcefiles${relativeUrl}`;
    return url;
  }

  /** Get all alignment source files for the current tenant */
  public getSourceFiles(states?: AlignmentSourceFileState[]): Observable<AlignmentSourceFile[]> {
    const url = this.getUrl();
    const params = states
      ? new HttpParams({
          fromObject: {
            states: states,
          },
        })
      : undefined;
    return this.http.get<AlignmentSourceFile[]>(url, { params }).pipe(catchHttpError());
  }

  /** Get the specified alignment source file */
  public getSourceFile(sourceFileId: string): Observable<AlignmentSourceFile> {
    const url = this.getUrl(sourceFileId);
    return this.http.get<AlignmentSourceFile>(url).pipe(catchHttpError());
  }

  /** Create a source file by uploading a selected file and reports progress until completed */
  public createSourceFile(file: File): Observable<UploadProgress<AlignmentSourceFile>> {
    const formData = new FormData();
    formData.append('alignmentFile', file, file.name);

    const url = this.getUrl();
    return this.http
      .post<AlignmentSourceFile>(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          const uploadProgress: UploadProgress<AlignmentSourceFile> = {
            percentage: 0,
          };

          if (event.type === HttpEventType.UploadProgress && isDefined(event.total)) {
            uploadProgress.percentage = Math.round((100 * event.loaded) / event.total);
          } else if (event.type === HttpEventType.Response) {
            uploadProgress.percentage = 100;
            uploadProgress.result = event.body ?? undefined;
          }

          return uploadProgress;
        }),
        catchHttpError(),
      );
  }

  /** Delete the specified source file */
  public deleteSourceFile(sourceFileId: string): Observable<void> {
    const url = this.getUrl(sourceFileId);
    return this.http.delete<void>(url).pipe(catchHttpError());
  }
}
