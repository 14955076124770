import { Component, HostBinding } from '@angular/core';
import { AlignmentViewerPanelComponent } from './components';

@Component({
  standalone: true,
  imports: [AlignmentViewerPanelComponent],
  selector: 'nzc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostBinding('class') componentClasses = 'flex flex-col grow';
}
