import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, compose, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { arraySort } from '../../shared';
import {
  AddAlignments,
  HideAlignment,
  RemoveAlignment,
  SetActiveAlignment,
  SetAlignments,
  SetAlignmentsIsLoading,
  SetAlignmentsLoadError,
  ShowAlignment,
  UpdateAlignment,
} from './alignment.actions';
import { AlignmentLoadState, AlignmentViewModel } from './alignment.view-models';

export interface AlignmentStateModel {
  isLoading: boolean;
  loadingError?: string;
  alignments: AlignmentViewModel[];
}

const defaultState: AlignmentStateModel = {
  isLoading: false,
  alignments: [],
};

@State<AlignmentStateModel>({
  name: 'alignmentState',
  defaults: defaultState,
})
@Injectable()
export class AlignmentState {
  @Selector() static alignments(state: AlignmentStateModel): AlignmentViewModel[] {
    return arraySort(state.alignments, (a) => a.name);
  }

  @Selector() static selected(state: AlignmentStateModel): AlignmentViewModel | undefined {
    return state.alignments.find(
      (model) => model.show && model.active && model.state === AlignmentLoadState.Ready,
    );
  }

  @Selector() static isLoading(state: AlignmentStateModel): boolean {
    return state.isLoading;
  }

  @Selector() static loadingError(state: AlignmentStateModel): string | undefined {
    return state.loadingError;
  }

  @Action(SetAlignmentsIsLoading) setIsLoading(
    ctx: StateContext<AlignmentStateModel>,
    action: SetAlignmentsIsLoading,
  ) {
    ctx.patchState({ isLoading: action.isLoading });
  }

  @Action(SetAlignmentsLoadError) setLoadingError(
    ctx: StateContext<AlignmentStateModel>,
    action: SetAlignmentsLoadError,
  ) {
    ctx.patchState({ loadingError: action.error });
  }

  @Action(SetAlignments) setAlignments(
    ctx: StateContext<AlignmentStateModel>,
    action: SetAlignments,
  ) {
    ctx.setState(patch<AlignmentStateModel>({ alignments: action.alignments }));
  }

  @Action(SetActiveAlignment) setActiveAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: SetActiveAlignment,
  ) {
    const updateItems = ctx
      .getState()
      .alignments.map((model, idx) =>
        updateItem<AlignmentViewModel>(idx, patch({ active: model.id === action.alignmentId })),
      );

    ctx.setState(
      patch({
        alignments: compose(...updateItems),
      }),
    );
  }

  @Action(AddAlignments) addAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: AddAlignments,
  ) {
    ctx.setState(
      patch({
        alignments: append(action.alignments),
      }),
    );
  }

  @Action(UpdateAlignment) updateAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: UpdateAlignment,
  ) {
    ctx.setState(
      patch({
        alignments: updateItem((model) => model.id === action.alignmentId, patch(action.update)),
      }),
    );
  }

  @Action(RemoveAlignment) removeAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: RemoveAlignment,
  ) {
    this.hideAlignment(ctx, new HideAlignment(action.alignmentId));
    ctx.setState(
      patch({
        alignments: removeItem((model) => model.id === action.alignmentId),
      }),
    );
  }

  @Action(ShowAlignment) showAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: ShowAlignment,
  ) {
    this.updateAlignment(ctx, new UpdateAlignment(action.alignmentId, { show: true }));
  }

  @Action(HideAlignment) hideAlignment(
    ctx: StateContext<AlignmentStateModel>,
    action: HideAlignment,
  ) {
    this.updateAlignment(
      ctx,
      new UpdateAlignment(action.alignmentId, { show: false, active: false }),
    );
  }
}
