import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { Observable } from 'rxjs';
import { asHttpParams, catchHttpError } from '../../shared';
import { AppState } from '../../state';
import { Alignment, UomPreference } from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class AlignmentApiService {
  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {}

  private getUrl(alignmentId?: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();

    relativeUrl = isNil(alignmentId) ? relativeUrl : `/${alignmentId}${relativeUrl}`;

    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;

    const url = `${baseUrl}/tenants/${tenantId}/alignments${relativeUrl}`;
    return url;
  }

  /** Get all alignments for the current tenant */
  public getAlignments(uom?: UomPreference): Observable<Alignment[]> {
    const url = this.getUrl();
    const params = asHttpParams(uom);
    return this.http.get<Alignment[]>(url, { params }).pipe(catchHttpError());
  }

  /** Get the specified alignment */
  public getAlignment(alignmentId: string, uom?: UomPreference): Observable<Alignment> {
    const url = this.getUrl(alignmentId);
    const params = asHttpParams(uom);
    return this.http.get<Alignment>(url, { params }).pipe(catchHttpError());
  }

  /** Delete the specified alignment */
  public deleteAlignment(alignmentId: string): Observable<void> {
    const url = this.getUrl(alignmentId);
    return this.http.delete<void>(url).pipe(catchHttpError());
  }
}
