export interface TrimBimExportArgs {
  beginStation?: number;
  endStation?: number;
  colorName?: Color;
  segmentLength?: number;
  stationLabelSize?: number;
  stationLabelVerticalOffset?: number;
  stationLabelSpacing?: number;
}

export enum Color {
  AliceBlue = 'aliceblue',
  AntiqueWhite = 'antiquewhite',
  Aqua = 'aqua',
  Aquamarine = 'aquamarine',
  Azure = 'azure',
  Beige = 'beige',
  Bisque = 'bisque',
  Black = 'black',
  BlanchedAlmond = 'blanchedalmond',
  Blue = 'blue',
  BlueViolet = 'blueviolet',
  Brown = 'brown',
  BurlyWood = 'burlywood',
  CadetBlue = 'cadetblue',
  Chartreuse = 'chartreuse',
  Chocolate = 'chocolate',
  Coral = 'coral',
  CornflowerBlue = 'cornflowerblue',
  Cornsilk = 'cornsilk',
  Crimson = 'crimson',
  Cyan = 'cyan',
  DarkBlue = 'darkblue',
  DarkCyan = 'darkcyan',
  DarkGoldenrod = 'darkgoldenrod',
  DarkGray = 'darkgray',
  DarkGreen = 'darkgreen',
  DarkKhaki = 'darkkhaki',
  DarkMagenta = 'darkmagenta',
  DarkOliveGreen = 'darkolivegreen',
  DarkOrange = 'darkorange',
  DarkOrchid = 'darkorchid',
  DarkRed = 'darkred',
  DarkSalmon = 'darksalmon',
  DarkSeaGreen = 'darkseagreen',
  DarkSlateBlue = 'darkslateblue',
  DarkSlateGray = 'darkslategray',
  DarkTurquoise = 'darkturquoise',
  DarkViolet = 'darkviolet',
  DeepPink = 'deeppink',
  DeepSkyBlue = 'deepskyblue',
  DimGray = 'dimgray',
  DodgerBlue = 'dodgerblue',
  Firebrick = 'firebrick',
  FloralWhite = 'floralwhite',
  ForestGreen = 'forestgreen',
  Fuchsia = 'fuchsia',
  Gainsboro = 'gainsboro',
  GhostWhite = 'ghostwhite',
  Gold = 'gold',
  Goldenrod = 'goldenrod',
  Gray = 'gray',
  Green = 'green',
  GreenYellow = 'greenyellow',
  Honeydew = 'honeydew',
  HotPink = 'hotpink',
  IndianRed = 'indianred',
  Indigo = 'indigo',
  Ivory = 'ivory',
  Khaki = 'khaki',
  Lavender = 'lavender',
  LavenderBlush = 'lavenderblush',
  LawnGreen = 'lawngreen',
  LemonChiffon = 'lemonchiffon',
  LightBlue = 'lightblue',
  LightCoral = 'lightcoral',
  LightCyan = 'lightcyan',
  LightGoldenrodYellow = 'lightgoldenrodyellow',
  LightGray = 'lightgray',
  LightGreen = 'lightgreen',
  LightPink = 'lightpink',
  LightSalmon = 'lightsalmon',
  LightSeaGreen = 'lightseagreen',
  LightSkyBlue = 'lightskyblue',
  LightSlateGray = 'lightslategray',
  LightSteelBlue = 'lightsteelblue',
  LightYellow = 'lightyellow',
  Lime = 'lime',
  LimeGreen = 'limegreen',
  Linen = 'linen',
  Magenta = 'magenta',
  Maroon = 'maroon',
  MediumAquamarine = 'mediumaquamarine',
  MediumBlue = 'mediumblue',
  MediumOrchid = 'mediumorchid',
  MediumPurple = 'mediumpurple',
  MediumSeaGreen = 'mediumseagreen',
  MediumSlateBlue = 'mediumslateblue',
  MediumSpringGreen = 'mediumspringgreen',
  MediumTurquoise = 'mediumturquoise',
  MediumVioletRed = 'mediumvioletred',
  MidnightBlue = 'midnightblue',
  MintCream = 'mintcream',
  MistyRose = 'mistyrose',
  Moccasin = 'moccasin',
  NavajoWhite = 'navajowhite',
  Navy = 'navy',
  OldLace = 'oldlace',
  Olive = 'olive',
  OliveDrab = 'olivedrab',
  Orange = 'orange',
  OrangeRed = 'orangered',
  Orchid = 'orchid',
  PaleGoldenrod = 'palegoldenrod',
  PaleGreen = 'palegreen',
  PaleTurquoise = 'paleturquoise',
  PaleVioletRed = 'palevioletred',
  PapayaWhip = 'papayawhip',
  PeachPuff = 'peachpuff',
  Peru = 'peru',
  Pink = 'pink',
  Plum = 'plum',
  PowderBlue = 'powderblue',
  Purple = 'purple',
  Red = 'red',
  RosyBrown = 'rosybrown',
  RoyalBlue = 'royalblue',
  SaddleBrown = 'saddlebrown',
  Salmon = 'salmon',
  SandyBrown = 'sandybrown',
  SeaGreen = 'seagreen',
  SeaShell = 'seashell',
  Sienna = 'sienna',
  Silver = 'silver',
  SkyBlue = 'skyblue',
  SlateBlue = 'slateblue',
  SlateGray = 'slategray',
  Snow = 'snow',
  SpringGreen = 'springgreen',
  SteelBlue = 'steelblue',
  Tan = 'tan',
  Teal = 'teal',
  Thistle = 'thistle',
  Tomato = 'tomato',
  Turquoise = 'turquoise',
  Violet = 'violet',
  Wheat = 'wheat',
  White = 'white',
  WhiteSmoke = 'whitesmoke',
  Yellow = 'yellow',
  YellowGreen = 'yellowgreen',
}
