import { Directive, ElementRef, inject } from '@angular/core';
import { ModusControlSize } from '../directives/control-size.directive';
import { FORM_FIELD_ID_PROVIDER, FormFieldControl, SelectIdProvider } from '../modus-form-field';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */

@Directive({
  selector: 'select[modus-select]',
  exportAs: 'modusSelect',
  hostDirectives: [
    { directive: ModusControlSize, inputs: ['size'] },
    { directive: FormFieldControl, inputs: ['id', 'required', 'disabled'] },
  ],
  providers: [
    {
      provide: FORM_FIELD_ID_PROVIDER,
      useExisting: SelectIdProvider,
    },
  ],
})
export class ModusSelect {
  private _formFieldControl: FormFieldControl = inject(FormFieldControl);

  constructor() {
    const elementRef = inject(ElementRef);
    elementRef.nativeElement.classList.add(this._formFieldControl.controlType);
  }
}
