import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { asHttpParams, catchHttpError } from '../../shared';
import { AppState } from '../../state';
import {
  AlignmentProjection,
  AlignmentTransforamtion,
  GenerateAlignmentPositionsArgs,
  ICompactPlotSet,
  INumericAxis,
  PointCoordinate,
  PointStation,
  UomPreference,
} from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class AlignmentCalculationsApiService {
  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {}

  private getUrl(alignmentId: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();
    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const url = `${baseUrl}/tenants/${tenantId}/alignments/${alignmentId}${relativeUrl}`;
    return url;
  }

  /** Project a position to an alignment station */
  public projectToStation(
    alignmentId: string,
    pointCoordinate: PointCoordinate,
    uom?: UomPreference,
  ): Observable<AlignmentProjection> {
    const url = this.getUrl(alignmentId, '/projection/stations');
    const params = asHttpParams(uom);
    const body = {
      x: pointCoordinate.x,
      y: pointCoordinate.y,
      z: pointCoordinate.z,
    };
    return this.http.post<AlignmentProjection>(url, body, { params }).pipe(catchHttpError());
  }

  /** Tranform an alignment station to a position */
  public transformStation(
    alignmentId: string,
    pointStation: PointStation,
    uom?: UomPreference,
  ): Observable<AlignmentTransforamtion> {
    const url = this.getUrl(alignmentId, '/transformation/coordinates');
    const params = asHttpParams(uom);
    const body = {
      station: pointStation.station,
      offset: pointStation.offset,
      height: pointStation.height,
    };
    return this.http.post<AlignmentTransforamtion>(url, body, { params }).pipe(catchHttpError());
  }

  /** Batch transform an alignment station range to positions */
  public transformStations(
    alignmentId: string,
    args: GenerateAlignmentPositionsArgs,
    uom?: UomPreference,
  ): Observable<ICompactPlotSet<INumericAxis>> {
    const url = this.getUrl(alignmentId, '/transformation/coordinates/plotsets');
    const params = asHttpParams(uom);
    return this.http
      .post<ICompactPlotSet<INumericAxis>>(url, args, { params })
      .pipe(catchHttpError());
  }
}
