<!-- Header -->
<div class="flex flex-row">
  <div class="flex flex-col grow">
    <div class="font-semibold">Display Settings</div>
    <div class="text-sm text-secondary">{{ alignment.name }}</div>
  </div>
  <div class="flex flex-row items-center justify-end">
    <nzc-busy-indicator [busy]="saving()" label="Saving...">
      <button modus-icon-button (click)="close()" [disabled]="busy()">
        <md-icon>close</md-icon>
      </button>
    </nzc-busy-indicator>
  </div>
</div>

<!-- Loader -->
@if (!settings()) {
  <div class="flex flex-col grow items-center justify-center py-12">
    <nzc-busy-indicator [busy]="loading()">
      <div class="text-white bg-red-light rounded-sm p-2 text-sm">
        <div class="font-bold">Could not load display settings!</div>
      </div>
    </nzc-busy-indicator>
  </div>
} @else {
  <!-- Detail -->
  <div class="my-3">
    <form [formGroup]="formGroup">
      <modus-form-field class="modus-form-field-type-slider">
        <modus-label class="flex flex-row">
          <div class="grow">Station Range</div>
          <div class="font-semibold">
            {{ formGroup.value.beginStation | uom }} to {{ formGroup.value.endStation | uom }}
          </div>
        </modus-label>
        <mat-slider [min]="alignment.beginStation" [max]="alignment.endStation" [step]="1">
          <input
            modus-input
            matSliderStartThumb
            [formControl]="formGroup.controls.beginStation"
            (valueChange)="afterValueChanged()"
          />
          <input
            modus-input
            matSliderEndThumb
            [formControl]="formGroup.controls.endStation"
            (valueChange)="afterValueChanged()"
          />
        </mat-slider>
      </modus-form-field>

      <modus-form-field class="color-select mb-2">
        <modus-label>Color</modus-label>
        <div
          modus-text-prefix
          class="color-swatch"
          [style.background]="formGroup.controls.colorName.value"
        ></div>
        <select
          modus-select
          [formControl]="formGroup.controls.colorName"
          (change)="afterValueChanged()"
        >
          @for (color of colors; track color.key) {
            <option [value]="color.value">
              {{ color.key }}
            </option>
          }
        </select>
      </modus-form-field>

      <modus-form-field class="modus-form-field-type-slider">
        <modus-label class="flex flex-row">
          <div class="grow">Station Label Size</div>
          <div class="font-semibold">{{ formGroup.value.stationLabelSize | uom }}</div>
        </modus-label>
        <mat-slider [min]="0.1" [max]="1" [step]="0.1">
          <input
            modus-input
            matSliderThumb
            [formControl]="formGroup.controls.stationLabelSize"
            (valueChange)="afterValueChanged()"
          />
        </mat-slider>
      </modus-form-field>

      <modus-form-field class="modus-form-field-type-slider">
        <modus-label class="flex flex-row">
          <div class="grow">Station Label Vertical Offset</div>
          <div class="font-semibold">{{ formGroup.value.stationLabelVerticalOffset | uom }}</div>
        </modus-label>
        <mat-slider [min]="0.1" [max]="1" [step]="0.1">
          <input
            modus-input
            matSliderThumb
            [formControl]="formGroup.controls.stationLabelVerticalOffset"
            (valueChange)="afterValueChanged()"
          />
        </mat-slider>
      </modus-form-field>

      <modus-form-field class="modus-form-field-type-slider">
        <modus-label class="flex flex-row">
          <div class="grow">Station Label Spacing</div>
          <div class="font-semibold">{{ formGroup.value.stationLabelSpacing | uom }}</div>
        </modus-label>
        <mat-slider [min]="1" [max]="25" [step]="1">
          <input
            modus-input
            matSliderThumb
            [formControl]="formGroup.controls.stationLabelSpacing"
            (valueChange)="afterValueChanged()"
          />
        </mat-slider>
      </modus-form-field>
    </form>
  </div>
}
