import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { UnitOfMeasureApiService } from '../../api';
import { ConnectService } from '../../connect';
import { UnitType, UomConverter } from '../../shared';
import { AlignmentService } from '../alignment';
import { SourceFileService } from '../source-file';
import { SetSettings, SetUomConverter } from './app.actions';
import { AppSettings } from './app.models';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private store: Store,
    private connectService: ConnectService,
    private uomService: UnitOfMeasureApiService,
    private alignmentService: AlignmentService,
    private sourceFileService: SourceFileService,
  ) {}

  public async initialize(window: Window): Promise<void> {
    await this.initAppSettings();
    await this.connectService.initialize(window.parent);

    this.initUomConverter();
    this.sourceFileService.loadSourceFiles();
    this.alignmentService.loadAligments();
  }

  private async initAppSettings(): Promise<AppSettings> {
    const response: Response = await fetch('appsettings.json');
    const settings: AppSettings = await response.json();
    await firstValueFrom(this.store.dispatch(new SetSettings(settings)));
    return settings;
  }

  private initUomConverter() {
    this.uomService.getUnitTypes().subscribe((unitTypeDtos) => {
      const unitTypes = unitTypeDtos.map<UnitType>((type) => ({
        id: type.unitType,
        units: type.units,
      }));
      const uomConverter = new UomConverter(unitTypes);
      this.store.dispatch(new SetUomConverter(uomConverter));
    });
  }
}
