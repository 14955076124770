import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { Observable, of } from 'rxjs';
import { arrayRemove, arrayReplace } from '../../shared';
import { AppState } from '../../state';
import { Measurement } from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class AlignmentMeasurementApiService {
  private readonly localStorageKey = 'AlignmentMeasurements';
  private measurements: Map<string, Measurement[]>;

  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {
    this.measurements = this.loadMeasurements();
  }

  private getUrl(alignmentId: string, measurmentId?: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();

    relativeUrl = isNil(measurmentId) ? relativeUrl : `/${measurmentId}${relativeUrl}`;

    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const url = `${baseUrl}/tenants/${tenantId}/alignments/${alignmentId}/measurements${relativeUrl}`;
    return url;
  }

  /** Get all measurements for the specified alignment */
  // public getMeasurements(alignmentId: string): Observable<Measurement[]> {
  //   const url = this.getUrl(alignmentId);
  //   return this.http.get<Measurement[]>(url).pipe(catchHttpError());
  // }

  /** Creates a new measurement for the specified alignment */
  // public createMeasurement(alignmentId: string, measurement: Measurement): Observable<Measurement> {
  //   const url = this.getUrl(alignmentId);
  //   return this.http.post<Measurement>(url, measurement).pipe(catchHttpError());
  // }

  // /** Delete the specified measurement */
  // public deleteMeasurement(alignmentId: string, measurementId: string): Observable<void> {
  //   const url = this.getUrl(alignmentId, measurementId);
  //   return this.http.delete<void>(url).pipe(catchHttpError());
  // }

  // Mock implementations
  private getOrCreateMeasurements(alignmentId: string): Measurement[] {
    if (!this.measurements.has(alignmentId)) {
      this.measurements.set(alignmentId, []);
    }

    return this.measurements.get(alignmentId) ?? [];
  }

  public getMeasurements(alignmentId: string): Observable<Measurement[]> {
    return of([...this.getOrCreateMeasurements(alignmentId)]);
  }

  public createMeasurement(alignmentId: string, measurement: Measurement): Observable<Measurement> {
    const measurements = this.getOrCreateMeasurements(alignmentId);

    const saveMeasurement = { ...measurement };
    saveMeasurement.id = `${alignmentId}-measurement-${measurements.length + 1}`;
    saveMeasurement.alignmentId = alignmentId;
    measurements.push(saveMeasurement);

    this.persistMeasurements();

    return of(saveMeasurement);
  }

  public updateMeasurement(alignmentId: string, measurement: Measurement): Observable<Measurement> {
    const measurements = this.getOrCreateMeasurements(alignmentId);

    const saveMeasurement = { ...measurement };
    arrayReplace(measurements, measurement, saveMeasurement);

    this.persistMeasurements();

    return of(saveMeasurement);
  }

  public deleteMeasurement(alignmentId: string, measurementId: string): Observable<void> {
    const measurements = this.getOrCreateMeasurements(alignmentId);
    const measurement = measurements.find((m) => m.id == measurementId);
    arrayRemove(measurements, measurement);

    this.persistMeasurements();

    return of(undefined);
  }

  private loadMeasurements(): Map<string, Measurement[]> {
    const storedJson = localStorage.getItem(this.localStorageKey);
    if (storedJson) {
      const measurmentsObj = JSON.parse(storedJson);
      const measurements = new Map<string, Measurement[]>(Object.entries(measurmentsObj));
      return measurements;
    }

    return new Map<string, Measurement[]>();
  }

  private persistMeasurements(): void {
    const json = JSON.stringify(Object.fromEntries(this.measurements));
    localStorage.setItem(this.localStorageKey, json);
  }
}
