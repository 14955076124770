export class ConfirmDialogData {
  constructor(message: string);
  constructor(message: string, title: string);
  constructor(
    public message: string,
    public title = 'Confirm',
    public okText = 'Yes',
    public cancelText = 'No',
    public hasCancelButton = true,
  ) {}
}
