import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class TenantIdProvider {
  constructor(private store: Store) {}

  /** Creates a tenant id from the current Connect project in the format of `trn:connect:projects:$regionId:$projectId` */
  public getTentantId(): string {
    const connectProject = this.store.selectSnapshot(AppState.project);
    const tenantId = `trn:connect:projects:${connectProject.location}:${connectProject.id}`;
    return tenantId;
  }
}
