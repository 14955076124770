export interface IEnumKeyValue<T extends string | number> {
  key: string;
  value: T;
}

/** Gets the key and value pairs for an enum */
export function enumPairs<T extends string | number>(enumType: object): IEnumKeyValue<T>[] {
  const values = Object.keys(enumType).map((k) => {
    return {
      key: k,
      value: (enumType as never)[k],
    };
  });

  return values;
}

/** Gets the array of keys for an enum */
export function enumKeys(enumType: object): string[] {
  return enumPairs(enumType).map((p) => p.key);
}

/** Gets the array of values for an enum */
export function enumValues<T extends string | number>(enumType: object): T[] {
  return enumPairs<T>(enumType).map((p) => p.value);
}
