import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { arraySort } from '../../shared';
import {
  AddSourceFile,
  RemoveSourceFile,
  SetSourceFiles,
  SetSourceFilesIsLoading,
  SetSourceFilesLoadError,
  UpdateSourceFile,
} from './source-file.actions';
import { SourceFileViewModel } from './source-file.view-models';

export interface SourceFileStateModel {
  isLoading: boolean;
  loadingError?: string;
  sourceFiles: SourceFileViewModel[];
}

const defaultState: SourceFileStateModel = {
  isLoading: false,
  sourceFiles: [],
};

@State<SourceFileStateModel>({
  name: 'sourceFileState',
  defaults: defaultState,
})
@Injectable()
export class SourceFileState {
  @Selector() static sourceFiles(state: SourceFileStateModel): SourceFileViewModel[] {
    return arraySort(state.sourceFiles, (f) => f.filename);
  }

  @Selector() static isLoading(state: SourceFileStateModel): boolean {
    return state.isLoading;
  }

  @Selector() static loadingError(state: SourceFileStateModel): string | undefined {
    return state.loadingError;
  }

  @Action(SetSourceFilesIsLoading) setIsLoading(
    ctx: StateContext<SourceFileStateModel>,
    action: SetSourceFilesIsLoading,
  ) {
    ctx.patchState({ isLoading: action.isLoading });
  }

  @Action(SetSourceFilesLoadError) setLoadingError(
    ctx: StateContext<SourceFileStateModel>,
    action: SetSourceFilesLoadError,
  ) {
    ctx.patchState({ loadingError: action.error });
  }

  @Action(SetSourceFiles) setSourceFiles(
    ctx: StateContext<SourceFileStateModel>,
    action: SetSourceFiles,
  ) {
    ctx.setState(patch<SourceFileStateModel>({ sourceFiles: action.sourceFiles }));
  }

  @Action(AddSourceFile) addSourceFile(
    ctx: StateContext<SourceFileStateModel>,
    action: AddSourceFile,
  ) {
    ctx.setState(
      patch({
        sourceFiles: append([action.sourceFile]),
      }),
    );
  }

  @Action(UpdateSourceFile) updateSourceFile(
    ctx: StateContext<SourceFileStateModel>,
    action: UpdateSourceFile,
  ) {
    ctx.setState(
      patch({
        sourceFiles: updateItem((model) => model.id === action.sourceFileId, patch(action.update)),
      }),
    );
  }

  @Action(RemoveSourceFile) removeSourceFile(
    ctx: StateContext<SourceFileStateModel>,
    action: RemoveSourceFile,
  ) {
    ctx.setState(
      patch({
        sourceFiles: removeItem((model) => model.id === action.sourceFileId),
      }),
    );
  }
}
