import { Component, HostBinding, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'nzc-busy-indicator',
  standalone: true,
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss'],
  imports: [MatProgressSpinnerModule],
})
export class BusyIndicatorComponent {
  @HostBinding('class') componentClass = 'nzc-busy-indicator';

  @Input() public busy = false;
  @Input() @HostBinding('class.show-label') public showLabel = true;
  @Input() public label = 'Loading...';
}
