import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { Observable } from 'rxjs';
import { catchHttpError } from '../../shared';
import { AppState } from '../../state';
import { Setting } from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class SettingApiService {
  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {}

  private getUrl(key?: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();

    relativeUrl = isNil(key) ? relativeUrl : `/${key}${relativeUrl}`;

    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const url = `${baseUrl}/tenants/${tenantId}/settings${relativeUrl}`;
    return url;
  }

  /** Get the specified setting */
  public getSetting<T>(key: string): Observable<Setting<T>> {
    const url = this.getUrl(key);
    return this.http.get<Setting<T>>(url).pipe(catchHttpError());
  }

  /** Create or update a setting */
  public createOrUpdateSetting<T>(key: string, value: T): Observable<Setting<T>> {
    const url = this.getUrl(key);
    const setting: Setting<T> = {
      key,
      value: value,
    };
    return this.http.put<Setting<T>>(url, setting).pipe(catchHttpError());
  }

  /** Delete the specified setting */
  public deleteSetting(key: string): Observable<void> {
    const url = this.getUrl(key);
    return this.http.delete<void>(url).pipe(catchHttpError());
  }
}
