<!-- Header -->
<div class="flex flex-row justify-end p-3">
  <button modus-icon-button modus-tooltip="Measure" (click)="measure()" tooltipClass="arrow-right">
    <md-icon>ruler</md-icon>
  </button>
</div>

<!-- Loader -->
@if (measurements.length === 0) {
  <div class="flex flex-col grow items-center justify-center p-3">
    <nzc-busy-indicator [busy]="loading">
      <!-- No items -->
      <div class="flex flex-col items-center justify-center">
        <div class="text-center">
          <div class="text-base font-semibold">No measurments</div>
          <div class="text-sm mt-2">
            Looks like you haven't saved any measurments yet. Start by creating a new measurment.
          </div>
        </div>
      </div>
    </nzc-busy-indicator>
  </div>
} @else {
  <!-- List -->
  <div class="flex flex-col grow basis-1 overflow-y-auto">
    @for (measurement of measurements; track measurement.id) {
      <div class="flex flex-row min-h-[32px] text-sm hover:bg-gray-0 px-2">
        <!-- Icon -->
        @switch (measurement.state) {
          @case (MeasurementDisplayStatus.Hidden) {
            <button
              modus-icon-button
              modus-tooltip="Show measurement"
              tooltipClass="arrow-left"
              (click)="toggleMeasurement(measurement)"
            >
              <md-icon class="opacity-60">visibility_off</md-icon>
            </button>
          }
          @case (MeasurementDisplayStatus.Visible) {
            <button
              modus-icon-button
              modus-tooltip="Hide measurement"
              tooltipClass="arrow-left"
              (click)="toggleMeasurement(measurement)"
            >
              <md-icon>visibility_on</md-icon>
            </button>
          }
        }

        <!-- Text -->
        <div
          modus-tooltip="{{ measurement.name }}"
          class="flex items-center w-full overflow-hidden p-1"
        >
          <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap">
            {{ measurement.name }}
          </div>
        </div>

        <!-- Options -->
        <div class="flex flex-row items-center">
          <!-- Menu -->
          <button modus-icon-button [modusMenuTriggerFor]="measurementMenu">
            <md-icon>more_vertical</md-icon>
          </button>
          <md-menu #measurementMenu>
            <md-menu-item
              (itemClick)="editMeasurement(measurement)"
              [disabled]="measurement.deleting"
              >Edit</md-menu-item
            >
            <md-menu-item
              (itemClick)="deleteMeasurement(measurement)"
              [disabled]="measurement.deleting"
              >Delete</md-menu-item
            >
          </md-menu>
        </div>
      </div>
    }
  </div>
}
