/** Round the value to the specifed number of decimals */
export function round(value: number, decimals: number): number {
  if (value == null) {
    return value;
  }

  decimals = Math.pow(10, decimals);
  return Math.round(value * decimals) / decimals;
}

export function degToRad(degrees: number): number {
  return (degrees * Math.PI) / 180;
}
