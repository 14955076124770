import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { asHttpParams, catchHttpError } from '../../shared';
import { AppState } from '../../state';
import { TrimBimExportArgs, UomPreference } from '../models';
import { TenantIdProvider } from './tenant-id-provider';

@Injectable({
  providedIn: 'root',
})
export class AlignmentExportApiService {
  constructor(
    private store: Store,
    private tenantIdProvider: TenantIdProvider,
    private http: HttpClient,
  ) {}

  private getUrl(alignmentId: string, relativeUrl = ''): string {
    const tenantId = this.tenantIdProvider.getTentantId();
    const baseUrl = this.store.selectSnapshot(AppState.settings).endpoints.alignments.url;
    const url = `${baseUrl}/tenants/${tenantId}/alignments/${alignmentId}${relativeUrl}`;
    return url;
  }

  private getBlob(url: string, uom?: UomPreference): Observable<Blob> {
    const params = asHttpParams(uom);
    return this.http.get(url, { responseType: 'blob', params }).pipe(catchHttpError());
  }

  /** Exports the specified alignment in TrimBim format */
  public exportTrimBim(alignmentId: string, uom?: UomPreference): Observable<Blob> {
    const url = this.getUrl(alignmentId, '/trimbim');
    return this.getBlob(url, uom);
  }

  /** Gets the TrimBim display settings for the specified alignment */
  public getTrimBimSettings(
    alignmentId: string,
    uom?: UomPreference,
  ): Observable<TrimBimExportArgs> {
    const url = this.getUrl(alignmentId, '/trimbim/settings');
    const params = asHttpParams(uom);
    return this.http.get<TrimBimExportArgs>(url, { params }).pipe(catchHttpError());
  }

  /** Updates the TrimBim display settings for the specified alignment */
  public updateTrimBimSettings(
    alignmentId: string,
    settings: TrimBimExportArgs,
    uom?: UomPreference,
  ): Observable<void> {
    const url = this.getUrl(alignmentId, '/trimbim/settings');
    const params = asHttpParams(uom);
    return this.http.put<void>(url, settings, { params }).pipe(catchHttpError());
  }
}
