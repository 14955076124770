import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  Observable,
  filter,
  finalize,
  forkJoin,
  map,
  repeat,
  switchMap,
  takeWhile,
  timer,
} from 'rxjs';
import {
  AlignmentApiService,
  AlignmentSourceFile,
  AlignmentSourceFileApiService,
  AlignmentSourceFileState,
  UploadProgress,
} from '../../api';
import { DialogService, enumValues } from '../../shared';
import { AddAlignments, mapAlignment } from '../alignment';
import {
  AddSourceFile,
  RemoveSourceFile,
  SetSourceFiles,
  SetSourceFilesIsLoading,
  SetSourceFilesLoadError,
  UpdateSourceFile,
} from './source-file.actions';
import { SourceFileViewModel, mapSourceFile } from './source-file.view-models';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SourceFileService {
  constructor(
    private store: Store,
    private sourceFileApiService: AlignmentSourceFileApiService,
    private alignmentApiService: AlignmentApiService,
    private dialogService: DialogService,
  ) {}

  public loadSourceFiles(): void {
    this.store
      .dispatch([
        new SetSourceFilesIsLoading(true),
        new SetSourceFilesLoadError(undefined),
        new SetSourceFiles([]),
      ])
      .pipe(
        switchMap(() => this.getSourceFiles()),
        untilDestroyed(this),
        finalize(() => this.store.dispatch(new SetSourceFilesIsLoading(false))),
      )
      .subscribe({
        next: (sourceFiles) => {
          this.store.dispatch(new SetSourceFiles(sourceFiles));
        },
        error: (err) => {
          this.store.dispatch(new SetSourceFilesLoadError(err));
        },
      });
  }

  private getSourceFiles(): Observable<SourceFileViewModel[]> {
    // Exclude 'Ingested' files
    const includeStates = enumValues<string>(AlignmentSourceFileState)
      .filter((s) => s !== AlignmentSourceFileState.Ingested)
      .map((s) => AlignmentSourceFileState[s as keyof typeof AlignmentSourceFileState]);

    return this.sourceFileApiService
      .getSourceFiles(includeStates)
      .pipe(map((result) => result.map(mapSourceFile)));
  }

  public deleteSourceFile(sourceFile: SourceFileViewModel): void {
    this.store
      .dispatch(new UpdateSourceFile(sourceFile.id, { deleting: true }))
      .pipe(
        switchMap(() =>
          this.dialogService.showConfirmation(
            `Are you sure you want to delete source file [${sourceFile.filename}]?`,
          ),
        ),
        untilDestroyed(this),
        filter((confirmed) => confirmed === true),
        switchMap(() => this.sourceFileApiService.deleteSourceFile(sourceFile.id)),
        switchMap(() => this.store.dispatch(new RemoveSourceFile(sourceFile.id))),
        finalize(() =>
          this.store.dispatch(new UpdateSourceFile(sourceFile.id, { deleting: false })),
        ),
      )
      .subscribe({
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }

  public uploadFiles(files: File[]): void {
    files.forEach((file, idx) => {
      // Create and add in-memory source file to show upload progress
      const uploadFileModel: SourceFileViewModel = {
        id: `${idx}-file.name`,
        filename: file.name,
        uploadProgress: { percentage: 0 },
        state: AlignmentSourceFileState.Uploading,
        errors: [],
        alignments: [],
        deleting: false,
      };

      let lastProgress: UploadProgress<AlignmentSourceFile> | undefined;
      this.store
        .dispatch(new AddSourceFile(uploadFileModel))
        .pipe(
          switchMap(() => this.sourceFileApiService.createSourceFile(file)),
          untilDestroyed(this),
        )
        .subscribe({
          next: (progress) => {
            lastProgress = progress; // Keep track of last progress for when complete

            this.store.dispatch(
              new UpdateSourceFile(uploadFileModel.id, { uploadProgress: progress }),
            );
          },
          error: (err) => {
            // Mark item as failed and set error
            this.store.dispatch(
              new UpdateSourceFile(uploadFileModel.id, {
                state: AlignmentSourceFileState.Failed,
                errors: [{ code: 'UploadError', message: err }],
              }),
            );
          },
          complete: () => {
            if (lastProgress?.result) {
              // Update temporary item witch actual item created source file
              const createdSourceFile = mapSourceFile(lastProgress.result);
              this.store.dispatch(new UpdateSourceFile(uploadFileModel.id, createdSourceFile));

              this.pollSourceFileStateUntilComplete(createdSourceFile);
            } else {
              // This should not happen, in case remove item
              this.store.dispatch(new RemoveSourceFile(uploadFileModel.id));
            }
          },
        });
    });
  }

  private pollSourceFileStateUntilComplete(sourceFile: SourceFileViewModel): void {
    // Ensure source file has not already completed (e.g. ingested)
    if (sourceFile.state !== AlignmentSourceFileState.Pending) {
      this.sourceFileProcessed(sourceFile);
      return;
    }

    // Start polling source file state until ingested or error
    this.sourceFileApiService
      .getSourceFile(sourceFile.id)
      .pipe(
        untilDestroyed(this),
        repeat({
          delay: () => {
            // Repeat every second until ingested or error
            return timer(1000).pipe(
              untilDestroyed(this),
              takeWhile(() => {
                return sourceFile.state === AlignmentSourceFileState.Pending;
              }),
            );
          },
        }),
      )
      .subscribe({
        next: (latestSourceFile) => {
          this.store.dispatch(new UpdateSourceFile(sourceFile.id, latestSourceFile));
          this.sourceFileProcessed(sourceFile);
        },
      });
  }

  private sourceFileProcessed(sourceFile: SourceFileViewModel): void {
    // Ensure source file has been ingested
    if (sourceFile.state !== AlignmentSourceFileState.Ingested) {
      return;
    }

    // If ingested, remove from source files and load valid alignments for the file
    const processAlignments$ = sourceFile.alignments
      .filter((a) => a.validationPassed)
      .map((a) => this.alignmentApiService.getAlignment(a.alignmentId).pipe(map(mapAlignment)));

    forkJoin(processAlignments$)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (alignments) => {
          this.store.dispatch([new RemoveSourceFile(sourceFile.id), new AddAlignments(alignments)]);
        },
        error: (err) => {
          // TODO: Show errors
          // this.toastr.error(err);
        },
      });
  }
}
