import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, catchError, switchMap, throwError } from 'rxjs';
import { AppState, AuthState, ClearAuth, Endpoints } from '../state';

export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const store = inject(Store);
  if (!isProtected(request.url, store)) return next(request);

  const token = store.selectSnapshot(AuthState.accessToken);

  request = request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(request).pipe(
    catchError((err) => {
      return err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized
        ? store.dispatch(ClearAuth).pipe(switchMap(() => EMPTY))
        : throwError(() => err);
    }),
  );
};

function isProtected(url: string, store: Store) {
  const endpoints = store.selectSnapshot(AppState.settings).endpoints;
  for (const key in endpoints) {
    const endpoint = endpoints[key as keyof Endpoints];
    if (url.startsWith(endpoint.url)) return endpoint.protected;
  }
  return false;
}
