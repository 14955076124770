import { ComponentType, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, Subject, filter, merge, take } from 'rxjs';

export enum OverlayPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  Center = 'center',
}

export const OVERLAY_DATA = new InjectionToken('OverlayData');

export class OverlayReference<R = unknown> {
  private afterClosedSubject = new Subject<R | undefined>();
  private result?: R;

  constructor(private overlayRef: OverlayRef) {
    this.handleClose();
  }

  private handleClose(): void {
    // On detach/dispose, emit afterClosed
    this.overlayRef
      .detachments()
      .pipe(take(1))
      .subscribe(() => {
        // Emit stored result on close
        this.afterClosedSubject.next(this.result);
        this.afterClosedSubject.complete();
      });
  }

  public close(result?: R): void {
    // Store result to be emmited on close (see handleClose)
    this.result = result;
    this.overlayRef.dispose();
  }

  // Specific generic type extension to prevent having to specify full type details for OverlayService.showOverlay<T, D, R>()
  public afterClosed<Rt extends R = R>(): Observable<Rt | undefined> {
    return this.afterClosedSubject.asObservable() as Observable<Rt>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private overlayRef?: OverlayReference;

  constructor(
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  /**
   * Opens an overlay containing the given component. Note that only one overlay can be open at a time.
   * @param component Type of the component to load into the overlay
   * @param data The data to be injected/passed to the component via {@link OVERLAY_DATA}
   * @param position The position of the overlay
   */
  public showOverlay<T, D, R = unknown>(
    component: ComponentType<T>,
    data: D,
    position: OverlayPosition,
  ): OverlayReference<R> {
    // Ensure previous overlay is closed
    this.closeOverlay();

    // Create new overlay for specified position
    const overlay = this.overlay.create({
      positionStrategy: this.createPositionStrategy(position),
      hasBackdrop: true,
      panelClass: ['overlay', `overlay-${position}`], // Refer to overlays.scss for styling
    });
    this.overlayRef = new OverlayReference(overlay);

    // Handle events that should close overlay
    merge(
      overlay.backdropClick(),
      overlay.keydownEvents().pipe(filter((event) => event.code == 'Escape')),
    )
      .pipe(take(1))
      .subscribe(() => {
        this.overlayRef?.close();
      });

    // Create new injector and component portal to provide overlay data
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: OVERLAY_DATA, useValue: data },
        { provide: OverlayReference, useValue: this.overlayRef },
      ],
    });

    // Show overlay
    const componentPortal = new ComponentPortal<T>(component, undefined, injector);
    overlay.attach(componentPortal);

    return this.overlayRef as OverlayReference<R>;
  }

  /** Closes the current overlay if any */
  public closeOverlay(): void {
    this.overlayRef?.close(undefined);
    this.overlayRef = undefined;
  }

  private createPositionStrategy(position: OverlayPosition): PositionStrategy {
    switch (position) {
      case OverlayPosition.Top:
        return this.overlay.position().global().top();

      case OverlayPosition.Right:
        return this.overlay.position().global().right();

      case OverlayPosition.Bottom:
        return this.overlay.position().global().bottom();

      case OverlayPosition.Left:
        return this.overlay.position().global().left();

      case OverlayPosition.Center:
        return this.overlay.position().global().centerVertically().centerHorizontally();

      default:
        throw new Error(`Overlay position not implemented: ${position}`);
    }
  }
}
