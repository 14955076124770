import { Alignment } from '../../api';

export enum AlignmentListFilterOption {
  ShowAll = 'ShowAll',
  ShowSelected = 'ShowSelected',
}

export enum AlignmentLoadState {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  Ready = 'Ready',
  Failed = 'Failed',
}

export interface AlignmentViewModel extends Readonly<Alignment> {
  readonly state: AlignmentLoadState;
  readonly show: boolean;
  readonly deleting: boolean;
  readonly active: boolean;
}

export function mapAlignment(alignment: Alignment): AlignmentViewModel {
  const viewModel: AlignmentViewModel = {
    ...alignment,
    state: AlignmentLoadState.NotLoaded,
    show: false,
    deleting: false,
    active: false,
  };

  return viewModel;
}
