import { AlignmentSourceFile, UploadProgress } from '../../api';

export interface SourceFileViewModel extends Readonly<AlignmentSourceFile> {
  readonly uploadProgress?: UploadProgress<AlignmentSourceFile>;
  readonly deleting: boolean;
}

export function mapSourceFile(sourceFile: AlignmentSourceFile): SourceFileViewModel {
  const viewModel: SourceFileViewModel = {
    ...sourceFile,
    deleting: false,
  };
  return viewModel;
}
