import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MonoTypeOperatorFunction, catchError, pipe, throwError } from 'rxjs';

/** Catches Http error responses, extracts the friendly message and rethrows an error */
export function catchHttpError<T>(): MonoTypeOperatorFunction<T> {
  return pipe(
    catchError((httpError: HttpErrorResponse) => {
      if (httpError.error instanceof ErrorEvent) {
        // Client or network error
        return throwError(() => httpError.error.message || 'Unknown client error');
      } else {
        // Server error
        if (httpError.error instanceof ProgressEvent) {
          return throwError(() => httpError.statusText || httpError.message || 'Server error');
        } else if (typeof httpError.error === 'string') {
          return throwError(() => httpError.error);
        } else {
          return throwError(
            () =>
              httpError.error?.message ||
              httpError.error?.error ||
              httpError.statusText ||
              httpError.message ||
              'Server error',
          );
        }
      }
    }),
  );
}

/** Create a new HttpParams instance from the specified value */
export function asHttpParams(value?: unknown): HttpParams | undefined {
  if (!value) {
    return undefined;
  }

  const params = new HttpParams({ fromObject: value as never });
  return params;
}
