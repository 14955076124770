import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  CacheNavigationArgs,
  CacheStationPositions,
  ClearNavigationState,
} from './navigation.actions';
import { NavigationArgs, StationPositions } from './navigation.view-models';

export interface NavigationStateModel {
  stationPositionsCache: {
    [key: string]: StationPositions;
  };
  navigationArgsCache: {
    [key: string]: NavigationArgs;
  };
}

const defaultState: NavigationStateModel = {
  stationPositionsCache: {},
  navigationArgsCache: {},
};

@State<NavigationStateModel>({
  name: 'navigationState',
  defaults: defaultState,
})
@Injectable()
export class NavigationState {
  static getStationPositions(
    alignmentId: string,
  ): (state: NavigationStateModel) => StationPositions {
    return createSelector([NavigationState], (state: NavigationStateModel) => {
      const settings = state.stationPositionsCache[alignmentId];
      return settings;
    });
  }

  static getNavigationArgs(alignmentId: string): (state: NavigationStateModel) => NavigationArgs {
    return createSelector([NavigationState], (state: NavigationStateModel) => {
      const navArgs = state.navigationArgsCache[alignmentId];
      return navArgs;
    });
  }

  @Action(CacheStationPositions) cacheStationPositions(
    ctx: StateContext<NavigationStateModel>,
    action: CacheStationPositions,
  ) {
    ctx.setState(
      patch({
        stationPositionsCache: patch({ [action.alignmentId]: action.stationPositions }),
      }),
    );
  }

  @Action(CacheNavigationArgs) cacheNavigationArgs(
    ctx: StateContext<NavigationStateModel>,
    action: CacheNavigationArgs,
  ) {
    ctx.setState(
      patch({
        navigationArgsCache: patch({ [action.alignmentId]: action.navigationArgs }),
      }),
    );
  }

  @Action(ClearNavigationState) clearNavigationState(
    ctx: StateContext<NavigationStateModel>,
    action: ClearNavigationState,
  ) {
    ctx.setState(
      patch({
        stationPositionsCache: patch({ [action.alignmentId]: undefined }),
        navigationArgsCache: patch({ [action.alignmentId]: undefined }),
      }),
    );
  }
}
