import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { hasModifierKey } from '@angular/cdk/keycodes';
import {
  ConnectedOverlayPositionChange,
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Subject, filter, fromEvent, map, take, takeUntil } from 'rxjs';
import { isDefined } from '../utils/utils';
import {
  MODUS_TOOLTIP_DEFAULT_OPTIONS,
  MODUS_TOOLTIP_SCROLL_STRATEGY,
  TooltipPosition,
} from './tooltip-options';
import { TooltipComponent } from './tooltip.component';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

@Directive({
  selector: '[modus-tooltip]',
  exportAs: 'modusTooltip',
})
export class ModusTooltip implements OnInit, OnDestroy {
  private readonly _element: HTMLElement = inject(ElementRef).nativeElement;
  private readonly _overlay = inject(Overlay);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _scrollDispatcher = inject(ScrollDispatcher);
  private readonly _options = inject(MODUS_TOOLTIP_DEFAULT_OPTIONS);
  private readonly _scrollStrategy = inject(MODUS_TOOLTIP_SCROLL_STRATEGY);
  private readonly _document = inject(DOCUMENT);

  private readonly _destroy$ = new Subject<void>();
  private readonly _panelClass = 'modus-tooltip-panel';
  private readonly _transformOrigin = '.modus-tooltip';
  private readonly _viewportMargin = 8;

  private _overlayRef?: OverlayRef;
  private _tooltipInstance?: TooltipComponent;
  private _currentPosition?: TooltipPosition;
  private _showTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private _hideTimeoutId: ReturnType<typeof setTimeout> | undefined;

  private _message = '';
  private _tooltipClass: string | string[] | Set<string> | { [key: string]: any } = '';
  private _position: TooltipPosition = this._options.position;
  private _showDelay: number = this._options.showDelay;
  private _hideDelay: number = this._options.hideDelay;
  private _disabled = false;

  private _showOnNextFocus = true;

  @Input('modus-tooltip')
  get message() {
    return this._message;
  }

  set message(value: string) {
    this._message = String(value).trim();

    if (this._tooltipInstance) {
      if (this._message) this.updateTooltipMessage();
      else this.hideTooltip();
    }
  }

  @Input('tooltipClass')
  get tooltipClass() {
    return this._tooltipClass;
  }

  set tooltipClass(value: string | string[] | Set<string> | { [key: string]: any }) {
    this._tooltipClass = value;
    if (this._tooltipInstance) {
      this.updateTooltipClass();
    }
  }

  @Input('tooltipPosition')
  get position(): TooltipPosition {
    return this._position;
  }

  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;

      if (this._overlayRef) {
        this.updatePosition();
      }
    }
  }

  @Input('tooltipShowDelay')
  get showDelay(): number {
    return this._showDelay;
  }

  set showDelay(value: NumberInput) {
    this._showDelay = coerceNumberProperty(value);
  }

  @Input('tooltipHideDelay')
  get hideDelay(): number {
    return this._hideDelay;
  }

  set hideDelay(value: NumberInput) {
    this._hideDelay = coerceNumberProperty(value);
  }

  @Input('tooltipDisabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    if (this._disabled) {
      this.hideTooltip();
    }
  }

  ngOnInit(): void {
    this.subscribeToTriggers();
  }

  ngOnDestroy(): void {
    this.cancelPendingTimeouts();
    this._destroy$.next();
    this._destroy$.complete();
  }

  show(delay = 0): void {
    if (this._disabled) return;

    this.cancelHideTimeout();

    this._showTimeoutId = setTimeout(() => {
      this.showTooltip();
      this._showTimeoutId = undefined;
    }, delay);
  }

  hide(delay = 0): void {
    this.cancelShowTimeout();

    this._hideTimeoutId = setTimeout(() => {
      this.hideTooltip();
      this._hideTimeoutId = undefined;
    }, delay);
  }

  private subscribeToTriggers() {
    fromEvent(this._element, 'mouseenter')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this.show(this._showDelay),
      });

    fromEvent(this._element, 'mouseleave')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this.hide(this._hideDelay),
      });

    fromEvent(window, 'blur')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this._showOnNextFocus = false;
        },
      });

    fromEvent(this._element, 'focus')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          if (!this._showOnNextFocus) {
            this._showOnNextFocus = true;
          } else {
            this.show(this._showDelay);
          }
        },
      });

    fromEvent(this._element, 'blur')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this.hide(this._hideDelay),
      });

    fromEvent(this._element, 'click')
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this._showOnNextFocus = false;
          this.hide(this._hideDelay);
        },
      });

    fromEvent<WheelEvent>(this._document, 'wheel')
      .pipe(
        filter(() => !!this._overlayRef),
        map((event) => this._document.elementFromPoint(event.screenX, event.screenY)),
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: (elementUnderPointer) => {
          const isElementUnderPointer = this._element === elementUnderPointer;
          const containsElementUnderPointer = this._element?.contains(elementUnderPointer);

          if (!isElementUnderPointer && !containsElementUnderPointer) {
            this.hide(this._hideDelay);
          }
        },
      });
  }

  private showTooltip() {
    if (this._overlayRef) {
      return;
    }

    this._overlayRef = this.createOverlayRef();

    this._tooltipInstance = this._overlayRef.attach(
      new ComponentPortal(TooltipComponent, this._viewContainerRef),
    ).instance;

    this.updateTooltipMessage();
    this.updateTooltipClass();
    this._tooltipInstance.show();
  }

  private hideTooltip() {
    if (!this._tooltipInstance) {
      this._overlayRef?.dispose();
      this._overlayRef = undefined;
      return;
    }

    // Wait for the TooltipComponent to animate to
    // its hidden state, then clean up when its done.
    this._tooltipInstance.hidden$.pipe(take(1), takeUntil(this._destroy$)).subscribe(() => {
      this._overlayRef?.dispose();
      this._overlayRef = undefined;
      this._tooltipInstance = undefined;
    });

    this._tooltipInstance.hide();
  }

  private createOverlayRef(): OverlayRef {
    const overlayRef = this._overlay.create(this.getOverlayConfig());

    overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.hideTooltip());

    overlayRef
      .keydownEvents()
      .pipe(
        filter((event) => event.key === 'Escape'),
        filter((event) => !hasModifierKey(event)),
        takeUntil(this._destroy$),
      )
      .subscribe((event) => {
        event.preventDefault();
        event.stopPropagation();
        this.hideTooltip();
      });

    overlayRef
      .detachments()
      .pipe(
        filter(
          () =>
            // only if overlayRef has not been disposed yet, e.g. closed by scrolling
            isDefined(this._overlayRef?.hostElement) || isDefined(this._overlayRef?.overlayElement),
        ),
      )
      .subscribe(() => {
        this._overlayRef?.dispose();
        this._overlayRef = undefined;
        this._tooltipInstance = undefined;
      });

    return overlayRef;
  }

  private getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      panelClass: this._panelClass,
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this._scrollStrategy(),
    });
    return overlayConfig;
  }

  private getPositionStrategy(): PositionStrategy {
    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._element);

    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this._element)
      .withPositions(this.getPositions())
      .withTransformOriginOn(this._transformOrigin)
      .withFlexibleDimensions(false)
      .withViewportMargin(this._viewportMargin)
      .withScrollableContainers(scrollableAncestors);

    positionStrategy.positionChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((change: ConnectedOverlayPositionChange) => {
        if (change.scrollableViewProperties.isOriginClipped) {
          return this.hide();
        }
        this.updatePositonClass(change.connectionPair);
      });

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    switch (this.position) {
      case 'left':
      case 'before':
        return [
          { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' },
          { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
        ];

      case 'right':
      case 'after':
        return [
          { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
          { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' },
        ];

      case 'above':
        return [
          { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
          { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
        ];

      case 'below':
        return [
          { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
          { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
        ];
    }
  }

  private updatePositonClass(connectionPair: ConnectionPositionPair) {
    const { originX, originY, overlayY } = connectionPair;
    let newPosition: TooltipPosition;

    if (overlayY === 'center') {
      newPosition = originX === 'start' ? 'left' : 'right';
    } else {
      newPosition = originY === 'top' ? 'above' : 'below';
    }

    const overlayRef = this._overlayRef;

    if (overlayRef) {
      overlayRef.removePanelClass(`${this._panelClass}-${this._currentPosition}`);
      overlayRef.addPanelClass(`${this._panelClass}-${newPosition}`);
    }

    this._currentPosition = newPosition;
  }

  private updateTooltipMessage() {
    if (this._tooltipInstance) {
      this._tooltipInstance.message = this._message;
      this._tooltipInstance.markForCheck();

      this._ngZone.onMicrotaskEmpty.pipe(take(1), takeUntil(this._destroy$)).subscribe(() => {
        if (this._overlayRef && this._tooltipInstance) {
          this._overlayRef.updatePosition();
        }
      });
    }
  }

  private updateTooltipClass() {
    if (this._tooltipInstance) {
      this._tooltipInstance.tooltipClass = this._tooltipClass;
      this._tooltipInstance.markForCheck();
    }
  }

  private updatePosition() {
    if (!this._overlayRef) return;

    const strategy = this._overlayRef.getConfig()
      .positionStrategy as FlexibleConnectedPositionStrategy;

    const positions = this.getPositions();

    strategy.withPositions(positions);
    this._overlayRef.updatePosition();
  }

  private cancelShowTimeout() {
    if (this._showTimeoutId != null) {
      clearTimeout(this._showTimeoutId);
      this._showTimeoutId = undefined;
    }
  }

  private cancelHideTimeout() {
    if (this._hideTimeoutId != null) {
      clearTimeout(this._hideTimeoutId);
      this._hideTimeoutId = undefined;
    }
  }

  private cancelPendingTimeouts() {
    this.cancelShowTimeout();
    this.cancelHideTimeout();
  }
}
